import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { useCalcs } from "hooks";
import Button from "components/Button";
import { useMutation } from "react-query";
import Text from "components/Text";
import { toast } from "react-toastify";
import TotalsWrapper from "views/CheckoutView/components/TotalsWrapper";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import {
  Elements,
  CardElement,
  useStripe,
  loadStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutConfirmPayment({
  nextActionType,
  clientSecret,
  handlePaymentSuccess,
  handlePaymentError,
}) {
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();

  const handleConfirmPayment = async () => {
    const response = await stripe.confirmCardPayment(clientSecret);
    // console.log(response);
    if (response?.paymentIntent?.status === "succeeded") {
      handlePaymentSuccess();
    } else {
      toast.error(
        "An error occured while trying to process your payment, please try again"
      );
      handlePaymentError();
    }
  };

  useEffect(() => {
    if (clientSecret) {
      handleConfirmPayment();
    }
  }, [clientSecret]);

  return <div />;
}
