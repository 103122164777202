import React from "react";
import AppleLogin from "react-apple-login";
import AppleIcon from "@mui/icons-material/Apple";
import { PropTypes } from "prop-types";
import styles from "./AppleLoginButton.module.scss";

export default function AppleLoginButton({ handleLogin }) {
  const appleResponse = (response) => {
    handleLogin(response.code);
  };

  return (
    <div className={styles.appleSsoWrapper}>
      <AppleLogin
        clientId={`${process.env.REACT_APP_APPLE_CLIENT_ID}`}
        redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT_URI}`}
        usePopup={false}
        callback={appleResponse}
        scope="email name"
        responseMode="form_post"
        render={(props) => (
          <div className={styles.appleButton} onClick={props.onClick}>
            <AppleIcon color="black" />
            <span>Continue with Apple</span>
          </div>
        )}
      />
    </div>
  );
}

AppleLoginButton.propTypes = {
  handleLogin: PropTypes.func,
};

AppleLoginButton.defaultProps = {
  handleLogin: () => {},
};
