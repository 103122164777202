import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import Text from "components/Text";
import { Helmet } from "react-helmet";
import blankaLogo from "assets/images/logo.png";
import { Controller, useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

import Button from "components/Button";
import { setToken } from "state/sessionSlice";
import { login } from "api";
import styles from "./Woocommerce.module.scss";

export default function Woocommerce() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);
  const loginMutation = useMutation(login);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  // save shop url for when they login
  useEffect(() => {
    /// /// connection a store  /////////
    const storeURL = new URLSearchParams(window.location.search).get("user_id");
    const storeToken = new URLSearchParams(window.location.search).get("token");

    if (storeURL) {
      window.sessionStorage.setItem(
        "woocommerce_store",
        JSON.stringify({ url: storeURL, token: storeToken })
      );
    }

    // always go to the connect page, main will intercept if the store has not finished onboarding
    navigate("/profile/connect");
  }, []);

  return <div className={styles.templateLogin} />;
}
