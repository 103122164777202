import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";
import ModalWrapper from "components/ModalWrapper";
import Text from "components/Text";
import img from "assets/images/icon-error.png";
import { useMediaQuery } from "react-responsive";
import styles from "./ConfirmUnbrandedOrderModal.module.scss";

export default function ConfirmUnbrandedOrderModal({
  open,
  handleConfirm,
  handleClose,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <ModalWrapper
      isOpen={open}
      className={styles.isPeach}
      handleClose={handleClose}
      data-testid="ConfirmUnbrandedOrderModal"
    >
      <div
        className={styles.ConfirmUnbrandedOrderModal}
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className={styles.contentContainer}>
          <Text
            className={`${styles.textHeader} text--upper`}
            color="white"
            variant="body1"
            fontSize={15}
          >
            Unbranded Order
          </Text>
          <p className={`${styles.textWarning} text--nunito`}>
            <span className="text--bold text--nunito">
              ALL SAMPLES ARE UNBRANDED
            </span>
            , so they{" "}
            <span className="text--bold text--nunito">
              DO NOT HAVE YOUR LOGO
            </span>{" "}
            printed on them.
          </p>
          <br />
          <p className={`text--nunito ${styles.textContent}`}>
            If you want <span className="text--bold text--nunito">BRANDED</span>{" "}
            samples, please order inventory.
            <br />
          </p>
          <Button
            variant="contained"
            color="secondary"
            hasChevron={!isMobile}
            size="large"
            onClick={(e) => handleConfirm()}
          >
            YES, I UNDERSTAND
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

ConfirmUnbrandedOrderModal.propTypes = {
  open: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

ConfirmUnbrandedOrderModal.defaultProps = {
  open: false,
  handleConfirm: () => {},
};
