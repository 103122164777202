import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Text from "components/Text";
import { toast } from "react-toastify";
import shopifyLogo from "assets/images/shopify-logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import wixLogo from "assets/images/wix-logo.png";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { setProfile } from "state/profileSlice";
import {
  updateShop,
  getUser,
  connectStore,
  connectWoocommerceStore,
  connectWixStore,
} from "api";
import { LinearProgress } from "@mui/material";
import PageHeader from "components/PageHeader";
import AccountNav from "components/AccountNav";
import {
  GenericSuccessNotification,
  GenericErrorNotification,
} from "components/Notifications";
import styles from "./AccountConnectStore.module.scss";

export default function AccountConnectStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shop = useSelector((state) => state.profile.shop);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shop) {
      return;
    }

    const connectShopify = async (store) => {
      try {
        await connectStore({ token: store.token, url: store.url });
        const response = await getUser();

        dispatch(setProfile(response.data));

        toast.success(
          <GenericSuccessNotification text="Your store is now connected to Blanka." />
        );

        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      } catch (e) {
        setError(
          "There was a problem connecting your store, please try again later"
        );
        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      }
    };

    const connectWoocommerce = async (store) => {
      try {
        await connectWoocommerceStore({ token: store.token, url: store.url });
        const response = await getUser();

        dispatch(setProfile(response.data));

        toast.success(
          <GenericSuccessNotification text="Your store is now connected to Blanka." />
        );

        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      } catch (e) {
        setError(
          "There was a problem connecting your store, please try again later"
        );
        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      }
    };

    const connectWix = async (store) => {
      try {
        await connectWixStore({ url: store.url });
        const response = await getUser();

        dispatch(setProfile(response.data));

        toast.success(
          <GenericSuccessNotification text="Your store is now connected to Blanka." />
        );

        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      } catch (e) {
        setError(
          "There was a problem connecting your store, please try again later"
        );
        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      }
    };

    // check for shopify store connection
    const shopifyStore = JSON.parse(sessionStorage.getItem("shopify_store"));
    if (shopifyStore) {
      setLoading(true);
      connectShopify(shopifyStore);
    }

    // check for woocomerce store connection
    const woocommerceStore = JSON.parse(
      sessionStorage.getItem("woocommerce_store")
    );
    if (woocommerceStore) {
      setLoading(true);
      connectWoocommerce(woocommerceStore);
    }

    // check for wix store connection
    const wixStore = JSON.parse(sessionStorage.getItem("wix_store"));
    if (wixStore) {
      setLoading(true);
      connectWix(wixStore);
    }
  }, []);

  return (
    <>
      <Text
        className="text--align-left text--spacing-0"
        color="medium-gray"
        fontSize={24}
        variant="h1"
      >
        Connect your online store
      </Text>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div className={styles.storesContainer}>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <a
              target="_blank"
              href="https://apps.shopify.com/blanka"
              rel="noreferrer"
            >
              <img src={shopifyLogo} alt="shopify logo" />
            </a>
            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              Shopify
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <a
              target="_blank"
              href="https://wordpress.org/plugins/blanka/"
              rel="noreferrer"
            >
              <img src={wooCommerceLogo} alt="woocommerce logo" />
            </a>

            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              WooCommerce
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerActive}`}
          >
            <a
              target="_blanka"
              href="https://www.wix.com/app-market/beauty-dropshipping-by-blanka"
            >
              <img src={wixLogo} alt="wix logo" width="125" />
            </a>

            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              Wix
            </Text>
          </div>
          <div
            className={`${styles.storeContainer} ${styles.storeContainerInactive}`}
            style={{ marginRight: "0px" }}
          >
            <img src={bigCommerceLogo} alt="bigcommerce logo" />
            <Text
              fontSize={15}
              className={`${styles.textComingSoon} text--bold text--white text--spacing-4`}
            >
              Coming Soon
            </Text>
            <Text
              fontSize={12}
              className={`text--nunito text--bold ${styles.textShopType}`}
            >
              BigCommerce
            </Text>
          </div>
        </div>
      )}
    </>
  );
}
