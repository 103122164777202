import apiService from "api/apiService";

export const getShippingCost = (countryCode, postal, weight) =>
  apiService.post("/inventory-orders/get-shipping-cost/", {
    country_code: countryCode,
    postal_code: postal,
    weight,
  });

export const completeInventoryOrder = (data) =>
  apiService.post("/inventory-orders/new-order/", data);

export const completeSamplesOrder = (order) =>
  apiService.post("/sample-orders/new-order/", {
    items: order.items,
    shipping: order.shipping,
    discount_id: order.discount_id,
  });

export const checkCoupon = (coupon) =>
  apiService.post("/payments/check-coupon/", { code: coupon });
