import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Text from "components/Text";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";

import Checkbox from "@mui/material/Checkbox";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import FormControlLabel from "@mui/material/FormControlLabel";
import Collapse from "@mui/material/Collapse";
import FormGroup from "@mui/material/FormGroup";
import TotalsWrapper from "views/CheckoutView/components/TotalsWrapper";
import PageHeader from "components/PageHeader";
import { getShippingCost } from "api";
import { setShippingCart } from "state/cartSlice";
import { CART_TYPE_SAMPLE, CART_TYPE_INVENTORY } from "constants/constants";
import { useDebounce } from "hooks";
import { COUNTRIES } from "../../constants/countries";
import AutoCompleteAddresses from "./components/AutoCompleteAddresses/AutoCompleteAddresses";
import styles from "./CheckoutShippingView.module.scss";

function CheckoutShippingView(props) {
  const navigate = useNavigate();
  // const { addShipping, nextStep, handleBack, clearShipping } = props;
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);

  const cart = useSelector((state) => state.cart);

  const cartShippingState = useSelector((state) => state.cart.shipping);
  const [shipping, setShipping] = useState(cartShippingState);

  const [shippingError, setShippingError] = useState(false);
  const [shippingCost, setShippingCost] = useState();
  const [storeAtBlanka, setStoreAtBlanka] = useState(true);
  const [shippingLoading, setShippingLoading] = useState();

  const [autoComplete, setAutoComplete] = useState([]);

  const onShippingChange = useDebounce(() => {
    setShippingLoading(true);
    dispatch(setShippingCart({}));
    let weight = 0;
    items.forEach((item) => {
      weight += item.product.weight * item.quantity;
    });

    getShippingCost(shipping.country.code, shipping.zip_code, weight)
      .then((res) => {
        setShippingLoading(false);
        dispatch(
          setShippingCart({
            ...shipping,
            shipping_cost: res.data.shipping_cost,
          })
        );

        // setShipping({ ...shipping, shipping_cost: res.data.shipping_cost });
      })
      .catch((err) => {
        setShippingLoading(false);
        setShippingError(true);
      });
  });

  useEffect(() => {
    // skip over shipping step if its a sample order
    if (cart.type === CART_TYPE_SAMPLE) {
      setStoreAtBlanka(false);
    }

    // reset shipping
    if (cart.shipping.shipping_cost) {
      onShippingChange();
    } else {
      dispatch(setShippingCart({}));
      setShippingCost(0);
    }
  }, []);

  useEffect(() => {
    if (
      storeAtBlanka ||
      !shipping?.zip_code ||
      !shipping?.country?.code ||
      shipping?.zip_code.length < 3
    ) {
      return;
    }

    onShippingChange();
    setShippingError(false);
  }, [shipping]);

  useEffect(() => {}, [autoComplete]);

  const handleAutoComplete = (autoComplete) => {
    // console.log(autoComplete);
    if (!autoComplete) {
      setShipping({
        ...shipping,
        address_one: "",
        city: "",
        state: "",
        country: { code: "", name: "" },
        zip_code: "",
      });
    } else {
      setShipping({
        ...shipping,
        address_one: autoComplete.address_one,
        city: autoComplete.city,
        state: autoComplete.state,
        country: autoComplete.country,
        zip_code: autoComplete.zip_code,
      });
    }
  };

  const handleSubmit = () => {
    // if (shipToMe) {
    //  addShipping({ ...shipping, store_at_blanka: blankaHoldsInv });
    // }
    navigate("/checkout/payment");
  };

  const shippingValid = () => {
    if (storeAtBlanka) {
      return true;
    }

    if (
      !shipping.first_name ||
      !shipping.last_name ||
      !shipping.address_one ||
      !shipping.city ||
      !shipping.country ||
      !shipping.zip_code ||
      !shipping.phone ||
      !cart.shipping.shipping_cost
    ) {
      return false;
    }

    return true;
  };

  const handleZipChange = debounce((value) => {
    setShipping({ ...shipping, zip_code: value });
  }, 1000);

  return (
    <div
      className={`samples-checkout template-inventory-checkout__shipping checkout-shipping ${styles.cartShipping}`}
    >
      <PageHeader title="Checkout" />
      <Card className={styles.card} variant="outlined">
        <div className="shipping-card-left">
          {cart.type !== CART_TYPE_SAMPLE && (
            <Text
              variant="body1"
              color="peach"
              className="text--left text--bold text--nunito text--fs-15 mb-20 "
            >
              WHERE DO YOU WANT YOUR INVENTORY STORED?
            </Text>
          )}

          {cart.type !== CART_TYPE_SAMPLE && (
            <FormGroup>
              <FormControlLabel
                onChange={(e) => {
                  if (e.target.checked) {
                    setStoreAtBlanka(true);
                    setShippingLoading(false);
                    dispatch(setShippingCart({}));
                    setShippingCost(0);
                  }
                }}
                disabled={cart.type === CART_TYPE_SAMPLE}
                checked={storeAtBlanka}
                className={styles.label}
                control={<Checkbox />}
                label="STORE MY INVENTORY AT THE BLANKA WAREHOUSE"
              />

              <FormControlLabel
                onChange={(e) => {
                  if (e.target.checked) {
                    setStoreAtBlanka(false);
                  }

                  dispatch(
                    setShippingCart({
                      ...shipping,
                      store_at_blanka: !e.target.checked,
                    })
                  );
                }}
                checked={!storeAtBlanka}
                control={<Checkbox />}
                className={styles.label}
                label="SHIP MY INVENTORY TO ME "
              />
            </FormGroup>
          )}

          <Collapse in={!storeAtBlanka}>
            <Text
              variant="h4"
              color="secondary"
              fontSize={24}
              className="text--left text--medium-gray text--georgia-bold text--fs-24 mt-15"
            >
              Shipping Information
            </Text>
            <form id="shipping-form" noValidate autoComplete="off">
              <div className="doubleLine">
                <TextField
                  id="first-name"
                  label="First Name"
                  autocomplete="given-name"
                  name="given-name"
                  variant="outlined"
                  value={shipping.first_name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  onChange={(e) =>
                    setShipping({ ...shipping, first_name: e.target.value })
                  }
                />

                <TextField
                  id="last-name"
                  label="Last Name"
                  variant="outlined"
                  autocomplete="family-name"
                  value={shipping.last_name}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setShipping({ ...shipping, last_name: e.target.value })
                  }
                />
              </div>

              {/* Google address Validation by autocomplete */}
              <div className="singleLine">
                <AutoCompleteAddresses
                  handleAutoComplete={handleAutoComplete}
                  onChange={(e) =>
                    setShipping({
                      ...shipping,
                      address_one: e.target.value,
                    })
                  }
                  value={shipping.address_one}
                />
              </div>

              <div className="singleLine">
                <TextField
                  name="address_two"
                  id="address-two"
                  label="Apartment, Suite (Optional)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={shipping.address_two}
                  variant="outlined"
                  onChange={(e) =>
                    setShipping({ ...shipping, address_two: e.target.value })
                  }
                />
              </div>
              <div className="tripleLine">
                <TextField
                  name="city"
                  id="city"
                  label="City"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  variant="outlined"
                  value={shipping.city}
                  onChange={(e) =>
                    setShipping({ ...shipping, city: e.target.value })
                  }
                />
                <TextField
                  name="state"
                  id="state"
                  label="State"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  variant="outlined"
                  value={shipping.state}
                  onChange={(e) =>
                    setShipping({ ...shipping, state: e.target.value })
                  }
                />

                <Autocomplete
                  id="combo-box-demo"
                  options={COUNTRIES}
                  name="country"
                  required
                  className={styles.addressDetailsContainer}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => {
                    setShipping({ ...shipping, country: newValue });
                  }}
                  value={shipping.country || null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Country"
                      variant="outlined"
                      autocomplete="country"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </div>

              <div className="doubleLine">
                <TextField
                  name="zip_code"
                  required
                  id="zip code"
                  label="Zip Code"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={shipping.zip_code}
                  onChange={(e) =>
                    setShipping({ ...shipping, zip_code: e.target.value })
                  }
                />
                <TextField
                  name="Phone"
                  id="Phone"
                  required
                  label="Phone"
                  autocomplete="tel"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={shipping.phone}
                  onChange={(e) =>
                    setShipping({ ...shipping, phone: e.target.value })
                  }
                />
              </div>
            </form>
          </Collapse>
        </div>

        <div className="shipping-card-right">
          <TotalsWrapper
            shippingError={shippingError}
            shipping={shipping}
            shippingCost={shipping.shipping_cost}
            shippingLoading={shippingLoading}
            items={items}
          />
        </div>
      </Card>

      <div className="checkout__actions">
        <div className="checkout__breadcrumbs">
          <CheckoutFooter backLink="/cart" activeStep={1} />
        </div>

        <div className="checkout-button-container">
          <Button
            disabled={!shippingValid() || shippingLoading}
            onClick={handleSubmit}
            variant="outlined"
            color="primary"
            className=" button button--primary"
          >
            Continue To Payment
          </Button>
        </div>
      </div>
    </div>
  );
}

CheckoutShippingView.propTypes = {
  addShipping: PropTypes.func,
};

CheckoutShippingView.defaultProps = {
  addShipping: () => {},
};

export default CheckoutShippingView;
