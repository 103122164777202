import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  PLAN_PRICING_MOBILE_TABLE_INFO,
  SCALE_PLAN,
} from "constants/constants";
import { CrossMarkIcon, CheckMarkIcon } from "components/Icons";
import Text from "../Text";
import Ribbon from "../Labels/Ribbon";

import styles from "./PlanTableCardMobile.module.scss";

export default function PlanTableCardMobile({
  planId,
  activePlan,
  hasTrial,
  onChange,
  handleClick,
  loading,
  alt,
}) {
  const getButtonText = (planId) => {
    if (activePlan === planId) {
      return "CURRENT";
    }
    if (hasTrial) {
      return "START FREE TRIAL";
    }
    return "GET STARTED";
  };
  const useRibbon = () => {
    if (PLAN_PRICING_MOBILE_TABLE_INFO[planId].name === SCALE_PLAN) {
      return true;
    }
    return null;
  };

  console.log(PLAN_PRICING_MOBILE_TABLE_INFO[planId].features_list);

  return (
    <Card
      style={
        PLAN_PRICING_MOBILE_TABLE_INFO[planId].name === SCALE_PLAN
          ? {
              border: `3px solid #D56F5B`,
            }
          : {}
      }
      className={alt ? styles.planCardCentered : styles.planCardMobile}
      data-testid="planCard"
      onClick={(e) => handleClick(planId)}
    >
      {useRibbon() && <Ribbon>Most Popular</Ribbon>}

      <Text
        fontSize={13}
        variant="h4"
        style={
          PLAN_PRICING_MOBILE_TABLE_INFO[planId].name === SCALE_PLAN ? {} : {}
        }
        className={`text--upper ${styles.planType}`}
      >
        {PLAN_PRICING_MOBILE_TABLE_INFO[planId].name}
      </Text>

      <CardContent className={styles.cardContent}>
        <div
          style={
            PLAN_PRICING_MOBILE_TABLE_INFO[planId].name === SCALE_PLAN
              ? { paddingTop: "0px", margin: "0 20px" }
              : {}
          }
          className={styles.planDetails}
        >
          {/* <li key={feature.feature}>{feature.feature}</li> */}
          <ul>
            {PLAN_PRICING_MOBILE_TABLE_INFO[planId].features_list.map(
              (feature) => {
                if (feature.feature === "Cross") {
                  return (
                    <li key={`Cross ${feature.id}`}>
                      <span>
                        <CrossMarkIcon />
                      </span>
                      <span> {feature.provided}</span>
                    </li>
                  );
                }
                if (feature.feature === "Check") {
                  return (
                    <li key={`Check ${feature.id}`}>
                      <span>
                        <CheckMarkIcon />
                      </span>
                      <span>{feature.provided}</span>
                    </li>
                  );
                }
                return (
                  <li key={`Feature ${feature.id}`}>
                    <span>{feature.feature}</span>
                    <span> {feature.provided}</span>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </CardContent>
      <CardActions className={styles.actionContainer}>
        {PLAN_PRICING_MOBILE_TABLE_INFO[planId].name !== null ? (
          <Button
            loading={loading}
            onClick={(e) => handleClick(planId)}
            color="primary"
            variant="contained"
            disabled={activePlan === planId}
            hasChevron={false}
            size="small"
            style={{
              height: "40px",
              width: "165px",
              fontSize: "11px",
              borderRadius: "2px",
              lineHeight: "1.2",
            }}
            className={styles.cta}
          >
            {getButtonText(planId)}
          </Button>
        ) : null}
      </CardActions>
      <Text
        fontSize={28}
        variant="h4"
        className={`${styles.textPrice} ${
          PLAN_PRICING_MOBILE_TABLE_INFO[planId].name === SCALE_PLAN &&
          styles.centerText
        }`}
      >
        {PLAN_PRICING_MOBILE_TABLE_INFO[planId].id &&
          `${
            PLAN_PRICING_MOBILE_TABLE_INFO[planId].price === null
              ? ""
              : `Then $${PLAN_PRICING_MOBILE_TABLE_INFO[planId].price}/month`
          }`}
      </Text>
    </Card>
  );
}

PlanTableCardMobile.propTypes = {
  planId: PropTypes.number,
  onChange: PropTypes.func,
};

PlanTableCardMobile.defaultProps = {
  planId: 1,
  onChange: () => {},
};
