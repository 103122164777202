import React, { useState } from "react";
import Text from "components/Text";
import Button from "components/Button";
import PlanCard from "components/PlanCard";
import { TRY_FOR_FREE, PLAN_INFO } from "constants/constants";
import { useSearchParams, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { updateShop } from "api";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { setShop, setOnboardingStep } from "state/profileSlice";

import { useWindowDimensions } from "hooks/useWindowDimensions";
import PlanTable from "../../../components/PlanTable";
import OnboardingWrapper from "../OnboardingWrapper";
import styles from "./OnboardingStepTwo.module.scss";
import OnboardingProgressStepper from "../OnboardingProgressStepper";

export default function OnboardingStepTwo() {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const shop = useSelector((state) => state.profile.shop);
  const profile = useSelector((state) => state.profile.profile);
  const [loading, setLoading] = useState(false);

  const handlePaidPlanSelect = async (id) => {
    setLoading(true);

    const response = await updateShop(shop.id, {
      onboarding_step: 2,
      plan: id,
    });

    window.gtag("event", "plan_upgrade", {
      event_category: "user",
      screen_name: `onboarding - ${location.pathname}`,
      value: `${PLAN_INFO[id].name}`,
    });

    dispatch(setShop(response.data));
  };

  const handleFreePlanSelect = async (id) => {
    setLoading(true);

    const response = await updateShop(shop.id, {
      onboarding_step: 3,
      plan: id,
      subscription_status: "active",
    });

    window.gtag("event", "plan_upgrade", {
      event_category: "user",
      screen_name: `onboarding - ${location.pathname}`,
      value: `${PLAN_INFO[id].name}`,
    });

    dispatch(setShop(response.data));
  };

  return (
    <OnboardingWrapper>
      <div className={styles.onboardingStepTwo} data-testid="OnboardingStepTwo">
        <Text
          className="text--align-center text--nunito mb-25"
          color="secondary"
          fontSize={width < 600 ? 14 : 24}
          variant="h1"
        >
          Try any plan free for 14 days!
        </Text>
        <Text
          className="text--align-center"
          color="medium-gray"
          fontSize={width < 600 ? 12 : 24}
          variant="h2"
        >
          Pick Your Plan
        </Text>

        <div className={styles.planCardsContainer}>
          <PlanTable
            onPaidPlanSelect={handlePaidPlanSelect}
            onFreePlanSelect={handleFreePlanSelect}
            loading={loading}
            constant={TRY_FOR_FREE}
          />
        </div>

        <OnboardingProgressStepper activeStep={0} />
      </div>
    </OnboardingWrapper>
  );
}
