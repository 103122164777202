import React from "react";
import PropTypes from "prop-types";
import MuiButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./Button.module.scss";

export default function Button({
  loading,
  disabled,
  color,
  variant,
  size,
  onClick,
  style,
  hasChevron,
  fullWidth,
  children,
  className,
  ...props
}) {
  // call this local handler to exam if the button is loading or now
  const handleClick = (event) => {
    try {
      if (!loading) {
        onClick(event); // continue with event
      }
    } catch (e) {
      onClick(event); // click event if any errors occurs
    }
  };

  return (
    <MuiButton
      data-testid="Button"
      disabled={disabled || loading}
      color={color}
      style={style}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      className={`${styles.button} ${className}`}
      onClick={handleClick}
      {...props}
    >
      <div className={`btnLabel ${loading ? styles.hidden : ""}`}>
        {children}
      </div>

      <div className={styles.buttonIconWrapper}>
        {loading && (
          <CircularProgress
            data-testid="CircularProgress"
            color="inherit"
            size={size === "small" ? 20 : 25}
          />
        )}
      </div>

      {hasChevron && (
        <ChevronRightIcon style={loading ? { visibility: "hidden" } : {}} />
      )}
    </MuiButton>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired, // this parm was odified because we have buttons with children that contins components too...
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  hasChevron: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "error",
    "info",
    "warning",
  ]),
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
};

Button.defaultProps = {
  onClick: undefined,
  loading: false,
  fullWidth: false,
  disabled: false,
  hasChevron: false,
  color: "success",
  variant: "contained",
  size: "medium",
};
