import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { connect, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "react-query";
import Button from "components/Button";
import { toast } from "react-toastify";
import {
  updatePlan,
  createSetupIntent,
  updateShopPayment,
  updateShop,
  downgradeToFreePlan,
  getUser,
} from "api";
import FormGroup from "@mui/material/FormGroup";
import { PLAN_FREE_ID, PLAN_INFO } from "constants/constants";
import queryClient from "api/queryClient";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import { STRIPE_THEME } from "theme";
import { GenericSuccessNotification } from "components/Notifications";
import Checkbox from "@mui/material/Checkbox";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import FormControlLabel from "@mui/material/FormControlLabel";
import Text from "components/Text";
import UpdatePaymentMethodModalForm from "components/UpdatePaymentMethodModalForm";

import styles from "./DunningModal.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

function DunningModal({ props, open, handleClose, allowClose }) {
  const changePlanMutation = useMutation(downgradeToFreePlan);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [stripeSuccess, setStripeSuccess] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [downgradeLoading, setDowngradeLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [responseError, setResponseError] = useState("");
  const shop = useSelector((state) => state.profile.shop);

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const getData = async () => {
      try {
        const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
        setClientSecret(response.data.client_secret);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    if (open) {
      getData();
    }
  }, []);

  const handleAddPaymentMethod = async (paymentId) => {
    try {
      await updateShopPayment(paymentId);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangePlan = () => {
    setDowngradeLoading(true);
    changePlanMutation.mutate(
      {},
      {
        onSuccess: (response) => {
          setDowngradeLoading(false);
          toast.success(
            <GenericSuccessNotification text="Your account has been updated." />
          );
          queryClient.refetchQueries(["get-user"]);
          handleClose();
        },
        onError: (err) => {
          setDowngradeLoading(true);
          queryClient.refetchQueries(["get-user"]);
        },
      }
    );
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  return (
    <ModalWrapper
      className="modal-dunning"
      handleClose={handleClose}
      isOpen={open}
      data-testid="PaymentMethodModal"
      allowClose={allowClose}
    >
      <div className={` modal__wrapper`}>
        {!stripeSuccess && (
          <div className="text--center ">
            <Text className="text--bold text__title" color="gray" fontSize={21}>
              Oops! Your payment information needs an update.
            </Text>
            <Text variant="body1" color="grey" fontSize={14}>
              Your payment failed. To continue using the{" "}
              <span className="text--bold">{shop.plan_name}</span>, please fill
              out the form below.
            </Text>
          </div>
        )}

        <div className="modal-container mb-50">
          <div className={styles.stripeContainer}>
            {loading && (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            )}
            {clientSecret && !loading && (
              <Elements options={options} stripe={stripePromise}>
                <UpdatePaymentMethodModalForm
                  handleAddPaymentMethod={handleAddPaymentMethod}
                  handleSuccess={() => setStripeSuccess(true)}
                  redirectParams="?dunning=true"
                />
              </Elements>
            )}
          </div>

          {!loading && !stripeSuccess && (
            <div className={styles.downgradeContainer}>
              <Text className="text--bold " variant="h6" color="gray">
                Want to downgrade your plan?
              </Text>
              <Text variant="body1" color="gray">
                Agree to the terms below, and you will be moved to the Free
                plan.
              </Text>

              <FormGroup className="form-group mb-20 mt-20 ">
                <FormControlLabel
                  className="text--sub"
                  control={
                    <Checkbox
                      checked={agreed}
                      onChange={(e) => setAgreed(!agreed)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="By checking this box, you agree to downgrade to the Free 
        plan and all of your custom products will be removed from your account."
                />
              </FormGroup>

              <Button
                disabled={!agreed}
                onClick={(e) => handleChangePlan()}
                color="primary"
                variant="contained"
                loading={downgradeLoading}
                className="button__submit button__downgrade"
              >
                Downgrade Plan
              </Button>
            </div>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}

DunningModal.propTypes = {
  open: PropTypes.bool,
};

DunningModal.defaultProps = {
  open: false,
};

export default DunningModal;
