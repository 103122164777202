import React from "react";
import Text from "components/Text";
import { useCalcs } from "hooks";

// import styles from "./CartTotals.module.scss";

export default function CartTotals({ items }) {
  const calcs = useCalcs();
  return (
    <div className="totals-container">
      <div className="totals-container__values-container">
        <Text className="text__totals text--nunito ">Subtotal</Text>
        <Text className="text__totals text--bold text--nunito">
          ${calcs.calcSubtotal(items)}
        </Text>
      </div>
      <div className="totals-container__values-container">
        <Text className="text__totals text--nunito ">Shipping</Text>
        <Text className="text__totals text--bold text--nunito">-</Text>
      </div>
      <div className="pb-10 totals-container__values-container">
        <Text className="text__totals text--nunito ">Processing Fee</Text>
        <Text className="text__totals text--bold text--nunito">
          ${calcs.calcFees(items)}
        </Text>
      </div>
      <div className="totals-container__values-container totals-container--border-top">
        <Text className="text__totals text--nunito text--align-left">
          Total
        </Text>
        <Text className="text__totals text--bold text--nunito">
          $
          {(
            Number(calcs.calcSubtotal(items)) + Number(calcs.calcFees(items))
          ).toFixed(2)}
        </Text>
      </div>
    </div>
  );
}
