import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, useNavigate } from "react-router-dom";
import { setProfile } from "state/profileSlice";
import {
  updateShop,
  getUser,
  connectStore,
  connectWoocommerceStore,
} from "api";

import {
  SHOP_INTEGRATION_TYPE_SHOPIFY,
  SHOP_INTEGRATION_TYPE_WOOCOMMERCE,
  SHOP_INTEGRATION_TYPE_WIX,
} from "constants/constants";

import { LinearProgress } from "@mui/material";
import PageHeader from "components/PageHeader";
import AccountNav from "components/AccountNav";
import Text from "components/Text";

import shopifyLogo from "assets/images/shopify-logo.png";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import wixLogo from "assets/images/wix-logo.png";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import styles from "./AccountStoreConnected.module.scss";

export default function AccountStoreConnected({ shop }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const getLogo = () => {
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_SHOPIFY) {
      return (
        <img alt="Shopify Logo" className="shopify-icon" src={shopifyLogo} />
      );
    }
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WOOCOMMERCE) {
      return (
        <img
          alt="Woocommerce Logo"
          className="shopify-icon"
          src={wooCommerceLogo}
        />
      );
    }
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WIX) {
      return <img alt="Wix Logo" className="shopify-icon" src={wixLogo} />;
    }

    return <p />;
  };

  return (
    <div className={styles.storeConnectedContainer}>
      <div
        className={styles.storeConnectContainer}
        data-testid="ProfileStoreConnect"
      >
        <Text
          className="text--align-left text--spacing-0 mb-10"
          color="medium-gray"
          fontSize={24}
          variant="h1"
        >
          My store
        </Text>
        <div className="store-connected-wrapper">
          <Text
            variant="body1"
            color="gray"
            fontSize={12}
            className="text--spacing-0-2"
          >
            YOUR {shop.integration_type} STORE IS CONNECTED
          </Text>

          <div className="store-connection-string">
            {getLogo()}
            {shop.url_display_name}
            <CheckCircleIcon />
          </div>
        </div>
      </div>
    </div>
  );
}
