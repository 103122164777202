import apiService from "api/apiService";

export const getCustomerOrders = (filter, page) => {
  let apiFilter = "";

  // change status so backend knows what to retrieve
  switch (filter) {
    case "ALL":
      apiFilter = null;
      break;
    case "UNFULFILLED":
      apiFilter = "UNFULFILLED|ORDERED";
      break;
    case "SHIPPED":
      apiFilter = "SHIPPED|FULLFILLED_BY_MERCHANT";
      break;
    default:
      apiFilter = filter;
  }

  const orderUrl = apiFilter
    ? `/orders/?status=${apiFilter}&page=${page}`
    : "/orders/";

  return apiService.get(orderUrl);
};

export const getInventoryOrders = () => apiService.get("/inventory-orders/");

export const updateOrder = (order) =>
  apiService.patch(`/orders/update/${order.id}`, order);

export const payCustomerOrder = (data) =>
  apiService.post(`/orders/${data.orderID}/place_order/`, {
    items: data.boxData,
    use_own_inventory: !!data.useOwnInventory,
  });

export const getSampleOrders = () => apiService.get("/sample-orders/");

export const orderBrandPlate = (type) =>
  apiService.post("/brandplate-orders/new-order/", { type });
