/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  isLoggedIn: !!token,
  token: {
    access_token: token ? token.access_token : "",
    refresh_token: token ? token.refresh_token : "",
  },
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setToken: (state, action) => {
      // set local state
      state.token.access_token = action.payload.access;
      state.token.refresh_token = action.payload.refresh;

      state.isLoggedIn = true;

      // store for refreshes
      localStorage.setItem("token", JSON.stringify({ ...state.token }));
    },
    logout: (state, action) => {
      const tokenCopy = { ...state.token };

      // only add persist token if username is sent
      // if user explicitly clicks logout then dont save any info

      if (action.payload) {
        localStorage.setItem(
          "PersistToken",
          JSON.stringify({
            token: tokenCopy.refresh_token,
            username: action.payload,
          })
        );
      }

      state.token = {};
      state.isLoggedIn = false;
      localStorage.removeItem("token");
      localStorage.removeItem("PersistToken");
      sessionStorage.removeItem("woocommerce_store");
      sessionStorage.removeItem("shopify_store");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, logout } = sessionSlice.actions;

export default sessionSlice.reducer;
