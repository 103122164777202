import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";
import {
  PLAN_INFO,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_VIP_ID,
} from "constants/constants";
import Card from "@mui/material/Card";
import AccountNav from "components/AccountNav";
import queryClient from "api/queryClient";
import {
  QUERY,
  createSetupIntent,
  updateShopPayment,
  updateShop,
  getUser,
} from "api";
import UpdatePaymentMethodForm from "components/UpdatePaymentMethodForm";
import { STRIPE_THEME } from "theme";

import { GenericSuccessNotification } from "components/Notifications";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

function AccountBillingUpdateView(props) {
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const shop = useSelector((state) => state.profile.shop);
  const [clientSecret, setClientSecret] = useState();

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const getData = async () => {
      try {
        // await getUser();
        const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
        setClientSecret(response.data.client_secret);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleAddPaymentMethod = async (paymentId) => {
    const respone = await updateShopPayment(paymentId);
    await queryClient.refetchQueries([QUERY.getUser]);
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  return (
    <div className="profile-billing-template root">
      <PageHeader title="My Account" />

      <AccountNav />

      {loading && (
        <Stack spacing={2}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={118} />
        </Stack>
      )}

      {(clientSecret || !loading) && (
        <Elements options={options} stripe={stripePromise}>
          <UpdatePaymentMethodForm
            handleAddPaymentMethod={handleAddPaymentMethod}
            onSuccess={() => {
              toast.success(
                <GenericSuccessNotification text="Your payment method has been saved." />
              );

              navigate("/profile/billing");
            }}
          />
        </Elements>
      )}
    </div>
  );
}

export default AccountBillingUpdateView;
