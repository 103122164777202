import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Text from "components/Text";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import queryClient from "api/queryClient";

import { QUERY, updateShopPayment, updatePlan } from "api";

import Button from "components/Button";

import styles from "./AddPaymentMethodForm.module.scss";

const RETURN_URL = String(
  process.env.REACT_APP_DOMAIN ||
    `${window.location.protocol}//${window.location.host}`
);

export default function AddPaymentMethodForm({
  handleNextStep,
  handleAddPaymentMethod,
  submitButtonText,
  showFreeTrialText,
  returnUrl,
  successMessageText,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const setData = async (setupIntent) => {
      await handleAddPaymentMethod(setupIntent.payment_method);
      await updatePlan(shop.plan);
      await handleNextStep();
    };

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );

    if (clientSecret) {
      // Retrieve the SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

        switch (setupIntent.status) {
          case "succeeded":
            setData(setupIntent);
            queryClient.refetchQueries([QUERY.getUser]);
            setMessage(
              successMessageText ||
                "Success! Your payment method has been saved. You will be redirected shortly"
            );

            break;

          case "processing":
            setMessage(
              "Processing payment details. We'll update you when processing is complete."
            );
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setErrorMessage(
              "Failed to process payment details. Please try another payment method."
            );
            break;
          default:
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    setLoading(true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl || RETURN_URL,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // setErrorMessage(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form>
      {!message && (
        <div>
          <PaymentElement
            id="payment-element"
            onChange={(event) => setFormComplete(event.complete)}
          />
          <div className={styles.buttonContainer}>
            {showFreeTrialText && (
              <Text className="text--bold">
                You will not be charged until your free trial ends. You can
                cancel anytime.
              </Text>
            )}

            <Button
              loading={loading}
              disabled={!stripe || !formComplete}
              onClick={(e) => handleSubmit(e)}
              className={styles.submitButton}
              color="secondary"
              variant="contained"
              type="submit"
            >
              {submitButtonText || "Sign Me Up"}
            </Button>
          </div>
        </div>
      )}
      {message && (
        <div className={styles.loadingContainer}>
          <Text className="text--align-center" variant="success">
            {message}
          </Text>
          <CircularProgress color="primary" className="mt-20" />
        </div>
      )}
      {errorMessage && (
        <Text className="text--align-center" variant="error">
          {errorMessage}
        </Text>
      )}
    </form>
  );
}

AddPaymentMethodForm.defaultProps = {
  showFreeTrialText: true,
};
