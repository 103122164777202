import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import PageHeader from "components/PageHeader";
import AccountNav from "components/AccountNav";
import Card from "@mui/material/Card";
import Text from "components/Text";
import Button from "components/Button";
import styles from "./AccountPasswordView.module.scss";

function AccountPasswordView(props) {
  const { getProfile, changePassword } = props;
  const profile = useSelector((state) => state.profile.profile);
  const shop = useSelector((state) => state.profile.shop);

  const [siteName, setSiteName] = useState("");

  // useEffect(() => {
  // }, [profile]);

  const hasUsablePassword = () => {
    if (profile && profile.has_usable_password) {
      return true;
    }
    return false;
  };

  return (
    <div className="template-profile-password root">
      <PageHeader title="My Account" />
      <AccountNav />

      <Card className="card" variant="outlined">
        <div className="header-text-container">
          <Text
            className="text__title text--georgia text--bold"
            color="medium-gray"
          >
            Password
          </Text>

          <Text
            className="text__secure-message text--nunito"
            variant="body1"
            color="textPrimary"
          >
            All passwords are secure and encrypted.
          </Text>

          {hasUsablePassword() && (
            <>
              <Text className="text__current-password text--nunito text--upper">
                Current Password
              </Text>

              <div className="password-display text--nunito">
                <span>************</span>
              </div>

              <div className="update-password__container">
                <Link
                  className="update-password text--nunito"
                  to="/profile/password/update"
                >
                  CHANGE YOUR PASSWORD
                </Link>
              </div>
            </>
          )}

          {!hasUsablePassword() && (
            <div className="flex--flex">
              <Button
                color="secondary"
                className=""
                variant="contained"
                component={Link}
                to="/profile/password/update"
              >
                Set App Password
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

AccountPasswordView.defaultProps = {
  profile: {},
};

export default AccountPasswordView;
