import PropTypes from "prop-types";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "components/PageHeader/PageHeader";
import Text from "components/Text";
import Card from "@mui/material/Card";
import AccountNav from "components/AccountNav";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import Button from "components/Button";

function AccountBillingView(props) {
  const profile = useSelector((state) => state.profile);
  const shop = useSelector((state) => state.profile.shop);
  const navigate = useNavigate();

  return (
    <div className="profile-billing-template root">
      <PageHeader title="My Account" />

      <AccountNav />

      <Card className="card" variant="outlined">
        <Text className="text__payment-header text--bold text--georgia">
          Payment Information
        </Text>

        <div className="paymentInfo">
          <Text
            className="card__secure-message text--nunito"
            variant="body1"
            color="textPrimary"
          >
            All transactions are secure and encrypted.
          </Text>

          <Text className="text__card-title text--nunito text--upper">
            Credit Card Number
          </Text>

          <div className="cc-display text--nunito mb-10">
            <p className="credit-card-stars">
              {" "}
              <span>
                **** **** ****{" "}
                {shop?.billing_address
                  ? shop?.billing_address?.credit_card_digits
                  : "****"}
              </span>
            </p>
          </div>

          <Text fontSize={8} variant="body1">
            NEED TO UPDATE YOUR INFORMATION? CLICK &quot;UPDATE
            INFORMATION&quot;.
          </Text>
        </div>
      </Card>

      <div className="flex--apart-center mt-20">
        <img
          className="poweredByStripe"
          src={poweredByStripeImg}
          alt="powered by stripe"
        />

        <Button
          variant="contained"
          color="primary"
          className=""
          onClick={(e) => navigate("/profile/billing/update")}
        >
          UPDATE Information
        </Button>
      </div>
    </div>
  );
}

export default AccountBillingView;
