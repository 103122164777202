import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { logout } from "state/sessionSlice";
import HeaderCartIcon from "components/Icons/HeaderCartIcon/HeaderCartIcon";
import Text from "components/Text";
import { useSelector, useDispatch } from "react-redux";
import styles from "./AppBar.module.scss";

const drawerWidth = 220;
const AppBarMui = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AppBar({ notificationCount, open, handleDrawerOpen }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  return (
    <AppBarMui
      position="fixed"
      className={styles.appBar}
      open={open}
      elevation={0}
    >
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Text
          color="dark-gray"
          fontSize={12}
          className="text--georgia text--bold hidden-mobile"
        >
          Latest from the blog: &nbsp;
        </Text>
        <a
          className={styles.textBlogLink}
          target="_blank"
          href="https://blankabrand.com/blogs/beyond-the-brand-beauty-blog"
          rel="noreferrer"
        >
          Beyond The Brand Blog
        </a>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          className={styles.center}
          style={{ display: "relative" }}
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Link to="/cart" className={styles.cartIconWrapper}>
            <HeaderCartIcon hasItems={cart.items.length > 0} />
            <span>{cart.items.length > 0 && <>({cart.items.length})</>}</span>
          </Link>
          <div className={styles.accountLinks}>
            <Link className={`${styles.textLink} text--nunito`} to="/profile">
              My Account
            </Link>
            <Link
              className={`${styles.textLink} text--nunito`}
              to="/logout?no_persist=true"
            >
              Logout
            </Link>
          </div>
        </Box>
      </Toolbar>
    </AppBarMui>
  );
}

AppBar.propTypes = {
  notificationCount: PropTypes.number,
  handleDrawerOpen: PropTypes.func,
};

AppBar.defaultProps = {
  notificationCount: 0,
  handleDrawerOpen: () => {},
};

export default AppBar;
