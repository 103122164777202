import React, { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

export function usePageViews(user) {
  const location = useLocation();

  useEffect(() => {
    // set as user
    try {
      if (user !== null && user.shop) {
        window.Intercom("update", {
          app_id: "pugbkanz",
          email: user?.email,
          name: user?.name,
          user_id: user?.email,
          created_at: parseInt(new Date().getTime() / 1000, 10),
        });

        window.FS.identify(user?.email, {
          displayName: user?.name,
          email: user?.email,
        });

        window.Intercom("boot", {
          app_id: "pugbkanz",
          email: user?.email,
          user_id: user?.id,
          created_at: user?.date_joined,
          custom_attributes: {
            brand_plate_created: user?.shop[0].brand_plate_created,
            subscription_status: user?.shop[0].subscription_status,
            plan: user?.shop[0].plan_name,
            custom_product_status: user?.shop[0].custom_product_status,
          },
          company: {
            company_id: user?.shop[0].id,
            website: user?.shop[0].url,
            name: user?.shop[0].id,
            Plan: user?.shop[0].plan_name,
            brand_plate_created: user?.shop[0].brand_plate_created,
            subscription_status: user?.shop[0].subscription_status,
            custom_product_status: user?.shop[0].custom_product_status,
          },
        });

        window.profitwell("user_email", `${user?.email}`);
      }
    } catch (err) {
      console.error(err);
    }

    // catch gtag for testing purposes
    try {
      window.gtag("event", "page_view", {
        page_title: location.pathname,
        page_location: location.pathname,
      });
    } catch (err) {
      // console.log("Error loading gtag");
    }
  }, [location]);
}
