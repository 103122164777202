import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import OnboardingWrapper from "views/onboarding/OnboardingWrapper";
import OnboardingStepTwo from "views/onboarding/OnboardingStepTwo";
import OnboardingStepThree from "views/onboarding/OnboardingStepThree";
import OnboardingStepFour from "views/onboarding/OnboardingStepFour";
import OnboardingStepFive from "views/onboarding/OnboardingStepFive";

// import { getUser, QUERY_GET_USER } from "api";
// import { setProfile } from "state/profileSlice";

// import styles from "./OnboardingView.module.scss";

// <OnboardingStepTwo />

export default function OnboardingView() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams(
    window.location.search
  );
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);
  const shop = useSelector((state) => state.profile.shop);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("onboarding", shop.onboarding_step);
    window.history.pushState("", "", `/?${urlParams.toString()}`);
  }, [shop]);

  const ONBOARDING_STEP = {
    1: <OnboardingStepTwo />, // pick plan
    2: <OnboardingStepThree />, // payment
    3: <OnboardingStepFour />, // connect store
    4: <OnboardingStepFive />, // store connected
  };

  return <div>{ONBOARDING_STEP[shop.onboarding_step]}</div>;
}
