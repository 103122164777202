import React, { useRef, useState } from "react";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import TextField from "@mui/material/TextField";
import { COUNTRIES } from "constants/countries";

const libraries = ["places"];

const mapFormatedCountries = () =>
  COUNTRIES.map((country) => country.code.toLowerCase());

const autoCompleteFieldsMapped = (place) => {
  const addressObj = {};

  place[0].address_components.forEach((component) => {
    switch (component.types[0]) {
      case "street_number":
        addressObj.streetNumber = component.long_name;
        break;
      case "route":
        addressObj.streetName = component.long_name;
        break;
      case "sublocality_level_1":
        addressObj.neighborhood = component.long_name;
        break;
      case "locality":
        addressObj.city = component.long_name;
        break;
      case "administrative_area_level_2":
        if (addressObj.city) {
          break;
        }
        addressObj.city = component.short_name;
        break;
      case "administrative_area_level_1":
        addressObj.state = component.short_name;
        break;
      case "country":
        addressObj.country = {
          name: component.long_name,
          code: component.short_name,
        };
        break;
      case "postal_code":
        addressObj.zip_code = component.long_name;
        break;
      default:
        break;
    }
  });

  const address = place[0].formatted_address;
  const index = address.indexOf(addressObj.city);

  if (index !== -1) {
    const reducedAddress = address.slice(0, index - 2);
    addressObj.address_one = reducedAddress;
  }

  return addressObj;
};

export default function AutoCompleteAddresses(props) {
  const { handleAutoComplete, value, onChange } = props;
  const inputRef = useRef();

  const handlePlaceChanged = () => {
    const place = inputRef.current.getPlaces();
    if (place) {
      const autoCompleteObj = autoCompleteFieldsMapped(place);
      handleAutoComplete(autoCompleteObj);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={libraries}
      region={mapFormatedCountries()}
    >
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChanged}
      >
        <TextField
          name="address_one"
          id="address-one"
          required
          InputLabelProps={{
            shrink: true,
          }}
          label="Address"
          variant="outlined"
          placeholder=""
          inputRef={inputRef}
          onChange={onChange}
          value={value}
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
}
