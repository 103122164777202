/* eslint-disable no-param-reassign */
import { createSlice, current } from "@reduxjs/toolkit";

/**
 * 
 * 
    Cart JSON structure
    {
        type: 'SAMPLE|INVENTORY',
        items: [
            id: '',
            sku: '',
            quantity: 0,
            branded_box: false|true
            product = {} //the full product
        ]
        shipping:{
            address_one
        }
        discount:{
          code:
          type: amount|percentage
          value: 
        }
        merchant_hold_inventory: false
    };
 */

const cart = JSON.parse(localStorage.getItem("cart"));

const initialState = {
  type: cart?.type ? cart.type : "",
  items: cart?.items ? cart.items : [],
  discount: cart?.discount
    ? cart.discount
    : { code: null, type: null, value: 0 },
  shipping: cart?.shipping
    ? cart.shipping
    : {
        store_at_blanka: true,
        shipping_cost: 0,
      },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartType: (state, action) => {
      state.type = action.type;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    addCart: (state, action) => {
      // check if any items in the cart
      if (current(state.items).length > 0) {
        // check if adding the same type

        if (state.type !== action.payload.type) {
          throw Error;
        }

        // need to check if product already in cart, then add to existing line item
        const { item } = action.payload;
        let found = false;
        const { id, quantity } = item;
        const updatedItem = { ...item, quantity: Number(quantity) };
        const newArray = [];

        current(state.items).map((currentItem) => {
          if (currentItem.id === id) {
            newArray.push({
              ...updatedItem,
              quantity: currentItem.quantity + updatedItem.quantity,
            });
            found = true;
          } else {
            newArray.push(currentItem);
          }
        });

        if (!found) {
          newArray.push(updatedItem);
        }

        state.items = newArray;
      } else {
        state.type = action.payload.type;
        state.items = [action.payload.item];
      }

      localStorage.setItem("cart", JSON.stringify(state));
    },
    updateCart: (state, action) => {
      const { item, quantity } = action.payload;
      const { id } = item;
      const updatedItem = { ...item, quantity: Number(quantity) };
      const newArray = [];

      current(state.items).map((currentItem) => {
        if (currentItem.id === id) {
          newArray.push({ ...updatedItem });
        } else {
          newArray.push(currentItem);
        }
      });

      state.items = newArray;

      localStorage.setItem("cart", JSON.stringify(state));
    },
    removeCart: (state, action) => {
      const id = action.payload;
      const newItems = state.items.filter((el) => el.id !== id);
      state.items = newItems;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCart: (state, action) => {
      state.items = [];
      state.type = "";
      state.shipping = {};
      state.discount = { code: null, type: null, value: 0 };
      localStorage.setItem("cart", JSON.stringify(state));
    },
    setShippingCart: (state, action) => {
      state.shipping = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearShipping: (state, action) => {
      state.shipping = {};
      localStorage.setItem("cart", JSON.stringify(state));
    },
    setCartDiscount: (state, action) => {
      state.discount = action.payload;
      localStorage.setItem("cart", JSON.stringify(state));
    },
    clearCartDiscount: (state, action) => {
      state.discount = {};
      localStorage.setItem("cart", JSON.stringify(state));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCartType,
  addCart,
  updateCart,
  removeCart,
  clearCart,
  setShippingCart,
  clearShippingCart,
  setCartDiscount,
  clearCartDiscount,
} = cartSlice.actions;

export default cartSlice.reducer;
