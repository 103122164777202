import React, { useEffect, useState } from "react";
import Text from "components/Text";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useCalcs } from "hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {
  CART_TYPE_INVENTORY,
  CART_TYPE_SAMPLE,
  DISCOUNT_TYPE_FIXED,
  DISCOUNT_TYPE_PERCENTAGE,
} from "constants/constants";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";

import styles from "./TotalsWrapper.module.scss";
import CheckoutDiscountForm from "../CheckoutDiscountForm";

export default function TotalsWrapper({ shippingLoading, shippingError }) {
  const calcs = useCalcs();

  const cart = useSelector((state) => state.cart);
  const items = useSelector((state) => state.cart.items);
  const shippingCost = useSelector(
    (state) => state.cart.shipping.shipping_cost
  );

  const [subtotal, setSubtotal] = useState();
  const [fees, setFees] = useState();
  const [total, setTotal] = useState();
  const [discount, setDiscount] = useState();
  const [autoDiscount, setAutoDiscount] = useState();

  useEffect(() => {
    if (!items.length > 0) {
      return;
    }

    setSubtotal(calcs.calcSubtotal(items));
    setFees(calcs.calcFees(items, shippingCost));
  }, [items, shippingCost]);

  useEffect(() => {
    if (!cart.discount.code || items.length === 0) {
      setDiscount(0);
      return;
    }

    setDiscount(calcs.calcDiscount(cart.discount, calcs.calcSubtotal(items)));
  }, [cart]);

  const getShippingCostState = () => {
    if (shippingLoading) {
      return <CircularProgress size={16} color="secondary" />;
    }

    return shippingError ? "" : "*";
  };

  const getInvDiscount = (subtotal) => {
    if (subtotal > 1499) {
      return `-$${(Number(calcs.calcSubtotal(items)) * 0.15).toFixed(2)} (15%)`;
    }
    if (subtotal > 999) {
      return `-$${(Number(calcs.calcSubtotal(items)) * 0.1).toFixed(2)} (10%)`;
    }
    if (subtotal > 499) {
      return `-$${(Number(calcs.calcSubtotal(items)) * 0.05).toFixed(2)} (5%)`;
    }
    return "";
  };

  const getAutoDiscount = (subtotal) => {
    /*
    if (subtotal > 1499) {
      return Number(calcs.calcSubtotal(items)) * 0.15;
    }
    if (subtotal > 999) {
      return Number(calcs.calcSubtotal(items)) * 0.1;
    }
    if (subtotal > 499) {
      return Number(calcs.calcSubtotal(items)) * 0.05;
    }
    return 0;

    */
  };

  const getCartTotal = () => {
    const cartTotal = (
      Number(fees || 0) +
      Number(subtotal || 0) +
      Number(shippingCost || 0) -
      Number(discount || 0) -
      Number(getAutoDiscount(subtotal) || 0)
    ).toFixed(2);

    return cartTotal;
  };

  return (
    <div className={styles.totalsContainer}>
      <div className={styles.costsContainer}>
        <div
          className={`${styles.costContentContainer} ${styles.costContentContainerSubtotal}`}
        >
          {cart.type === CART_TYPE_SAMPLE && <CheckoutDiscountForm />}
        </div>
        <div
          className={`${styles.costContentContainer} ${styles.costContentContainerSubtotal}`}
        >
          <Text
            fontSize={12}
            color="gray"
            className="text--upper text--spacing-2"
          >
            Subtotal
          </Text>

          <Text
            fontSize={12}
            color="gray"
            className={`text--bold text--spacing-2 ${styles.textTotalNumber}`}
          >
            ${subtotal}
          </Text>
        </div>

        {cart.type === CART_TYPE_SAMPLE && (
          <div className={styles.costContentContainer}>
            <Text
              fontSize={12}
              color="gray"
              className="text--upper text--spacing-2"
            >
              Discount
            </Text>

            <Text
              fontSize={12}
              color="gray"
              className={`text--bold text--spacing-2 ${styles.textTotalNumber}`}
            >
              {discount ? `-$${Number(discount).toFixed(2)}` : "*"}
            </Text>
          </div>
        )}

        <div className={styles.costContentContainer}>
          <Text
            fontSize={12}
            color="gray"
            className="text--upper text--spacing-2"
          >
            Shipping Estimate
          </Text>

          <Text
            fontSize={12}
            color="gray"
            className={`text--bold text--spacing-2 ${styles.textTotalNumber}`}
          >
            {shippingCost && !shippingLoading ? (
              `$${Number(shippingCost).toFixed(2)}`
            ) : (
              <span>{getShippingCostState()}</span>
            )}

            {shippingError && !shippingCost && (
              <Text color="error" fontSize={8}>
                Error Calculating shipping
              </Text>
            )}
          </Text>
        </div>

        <div
          className={`${styles.costContentContainer} ${styles.costContentContainerProcessingFee}`}
        >
          <Text
            fontSize={12}
            color="gray"
            className="text--upper text--spacing-2"
          >
            Processing Fee
          </Text>

          <Text
            fontSize={12}
            color="gray"
            className={`text--bold text--spacing-2 ${styles.textTotalNumber}`}
          >
            ${calcs.calcFees(items, shippingCost)}
          </Text>
        </div>

        <div
          className={`${styles.costContentContainer} ${styles.costContentContainerTotal}`}
        >
          <Text
            fontSize={12}
            color="gray"
            className="text--upper text--spacing-2"
          >
            Total
          </Text>

          <Text
            fontSize={12}
            color="gray"
            className={`text--bold text--spacing-2 ${styles.textTotalNumber}`}
          >
            $ {getCartTotal()}
          </Text>
        </div>
      </div>

      <div className={styles.poweredByContainer}>
        <img
          className={styles.poweredByStripe}
          src={poweredByStripeImg}
          alt="powered by stripe"
        />
      </div>
    </div>
  );
}
