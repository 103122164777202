import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Text from "components/Text";
import { toast } from "react-toastify";
import shopifyLogo from "assets/images/shopify-logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import wixLogo from "assets/images/wix-logo.png";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { setProfile } from "state/profileSlice";
import {
  updateShop,
  getUser,
  connectStore,
  connectWoocommerceStore,
} from "api";
import {
  GenericSuccessNotification,
  GenericErrorNotification,
} from "components/Notifications";
import { LinearProgress } from "@mui/material";
import PageHeader from "components/PageHeader";
import AccountNav from "components/AccountNav";
import AccountConnectStore from "./components/AccountConnectStore";
import AccountStoreConnected from "./components/AccountStoreConnected";

import styles from "./AccountStoreIntegrationView.module.scss";

export default function AccountStoreIntegrationView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shop = useSelector((state) => state.profile.shop);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // check if we are attempting to connect the store
    const connectStore = new URLSearchParams(window.location.search).get(
      "connect_store"
    );

    // show error if shop already has a store connected
    if (shop.url && connectStore) {
      toast.error(
        <GenericErrorNotification text="You cannot connect multiple stores to the same account" />
      );
      // remove url check
      navigate("/profile/connect");
    }
  }, []);

  return (
    <div className="template-profile-store">
      <PageHeader title="My Account" />
      <AccountNav />
      <div
        className={styles.storeConnectContainer}
        data-testid="ProfileStoreConnect"
      >
        {shop.store_connected ? (
          <AccountStoreConnected shop={shop} />
        ) : (
          <AccountConnectStore shop={shop} />
        )}
      </div>
    </div>
  );
}
