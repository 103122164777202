/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "components/Button";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import { QUERY, getProducts, updateShop, newVisualizationRequest } from "api";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Text from "components/Text";
import { NavLink, Link } from "react-router-dom";
import { routes } from "routes";
import blankaLogo from "assets/images/logo.png";
import { useSelector } from "react-redux";
import {
  PLAN_FREE_ID,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
  CUSTOM_PRODUCT_STATUS_IN_PROGRESS,
  CUSTOM_PRODUCT_STATUS_NONE,
} from "constants/constants";
import { HelpIcon } from "components/Icons";
import HeaderCartIcon from "components/Icons/HeaderCartIcon/HeaderCartIcon";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery, useMutation } from "react-query";
import { useMediaQuery } from "react-responsive";
import queryClient from "api/queryClient";
import { grey } from "@mui/material/colors";
import {
  NoLogoError,
  GenericSuccessNotification,
  NoBillingMethodError,
} from "components/Notifications";
import UpgradeBlock from "../Sidebar/components/UpgradeBlock";

import styles from "./SidebarMobile.module.scss";

const drawerWidth = "100%";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  alignItems: "space-between",
  justifyContent: "center",
  width: "100%",
  height: "80px",
  backgroundColor: "white",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // alignItems: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
  width: "100%",
  height: "80px",
}));

export default function SidebarMobile({ onOpenDrawer }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const cart = useSelector((state) => state.cart);
  const shop = useSelector((state) => state.profile.shop);

  const [visualizeLogoLoading, setVisualizeLogoLoading] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false);

  const visualizationMutation = useMutation(newVisualizationRequest);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  /**
   * send a request to visualize logo
   */
  const visualizeLogo = (overrideLogoCheck) => {
    // check if user is on free plan or does not have a logo uploaded yet
    if (shop.plan === PLAN_FREE_ID || !shop?.plan) {
      setOpenChangePlanModal(true);

      return;
    }

    if (!overrideLogoCheck && !shop.logo_uploaded) {
      setShowUploadLogoModal(true);
      return;
    }

    setVisualizeLogoLoading(true);

    visualizationMutation.mutate(
      {},
      {
        onSuccess: (response) => {
          setVisualizeLogoLoading(false);
          queryClient.refetchQueries([QUERY.getUser]);

          toast.success(
            <GenericSuccessNotification
              title="Hang tight!"
              text=" We’re creating your custom product images. You’ll receive an email when they are complete."
            />
          );
        },
        onError: (err) => {
          setVisualizeLogoLoading(false);
          toast.error("Error: Visualization request could not be sent");
        },
      }
    );
  };

  const handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
    setOpen(matches);
    onOpenDrawer(matches);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box className={styles.sidebarMobile}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className={styles.appBarWrapper}>
        <Toolbar className={styles.toolbarWrapper}>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src={blankaLogo} alt="blanka" className={styles.blankaLogo} />
          </Link>
          <Link to="/cart" className={styles.cartIconWrapper}>
            <HeaderCartIcon hasItems={cart.items.length > 0} />
            <span>{cart.items.length > 0 && <>({cart.items.length})</>}</span>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className={styles.draweHeaderWrapper}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon style={{ color: "#d56f5b" }} />
          </IconButton>
          <div className={styles.accountLinks}>
            <Link
              className={`${styles.textLink} text--nunito`}
              to="/profile"
              onClick={handleDrawerClose}
            >
              My Account
            </Link>
            <Link
              className={`${styles.textLink} text--nunito`}
              to="/logout?no_persist=true"
            >
              Logout
            </Link>
          </div>
        </DrawerHeader>
        <Divider />
        <List className={styles.submenu}>
          {routes
            .filter((route) => route.menu)
            .map((route, index) => (
              <div key={index}>
                {route.sectionTitle && (
                  <Text
                    fontSize={9}
                    variant="body1"
                    color="gray"
                    className="text--upper text--spacing-2-4 mt-25 ml-5"
                  >
                    {route.sectionTitle}
                  </Text>
                )}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "navItem activeItem" : "navItem"
                  }
                  to={route.path}
                  style={{ textDecoration: "none" }}
                  key={index}
                  onClick={() => {
                    handleDrawerClose(true);
                  }}
                >
                  <ListItem button key={route.name}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.name} color={grey} />
                  </ListItem>
                </NavLink>
                {/* {route.hasDividerBottom && <Divider />} */}
              </div>
            ))}

          <NavLink
            className="navItem"
            to="#"
            style={{ textDecoration: "none" }}
            key={99}
            onClick={() => {
              window.open("http://faq.blankabrand.com/");
            }}
          >
            <ListItem button key="help">
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </NavLink>
        </List>
        {shop.plan === PLAN_FREE_ID && <UpgradeBlock />}
        {isMobile &&
          shop.plan !== PLAN_FREE_ID &&
          shop.custom_product_status === CUSTOM_PRODUCT_STATUS_NONE && (
            <Button
              size="small"
              color="primary"
              onClick={() => visualizeLogo()}
              loading={visualizeLogoLoading}
              className={styles.btn}
            >
              Add my Logo To Products
            </Button>
          )}
        {isMobile &&
          shop.plan !== PLAN_FREE_ID &&
          shop.custom_product_status === CUSTOM_PRODUCT_STATUS_IN_PROGRESS && (
            <Button
              size="small"
              color="primary"
              disabled
              className={styles.btn}
            >
              Visualization in progress
            </Button>
          )}
        {isMobile &&
          shop.plan !== PLAN_FREE_ID &&
          shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE && (
            <div />
          )}
      </Drawer>
    </Box>
  );
}
