/* eslint-disable no-param-reassign */
import { useCallback, useState } from "react";
import {
  FEES_PERCENTAGE,
  FEES_CONSTANT,
  BRANDED_BOX_COST,
  DISCOUNT_TYPE_FIXED,
  DISCOUNT_TYPE_PERCENTAGE,
  SHIPPING_COST_BASE_US,
  SHIPPING_COST_INC_US,
  SHIPPING_COST_BASE_INT,
  SHIPPING_COST_INC_INT,
} from "constants/constants.js";

export function useCalcs() {
  return {
    calcSubtotal: useCallback((items) => {
      let total = 0;
      items.forEach((item) => {
        total += Number(item.product.cost) * item.quantity;

        if (item.branded_box) {
          total += item.quantity * BRANDED_BOX_COST;
        }
      });
      return total.toFixed(2);
    }),
    calcShipping: useCallback((countryCode, items) => {
      let shippingCost = 0;

      if (countryCode === "CA" || countryCode === "US") {
        shippingCost = SHIPPING_COST_BASE_US;
        const itemTotal = items.reduce((a, v) => (a += v.quantity), 0);
        shippingCost += itemTotal * SHIPPING_COST_INC_US;
      } else {
        shippingCost = SHIPPING_COST_BASE_INT;
        const itemTotal = items.reduce((a, v) => (a += v.quantity), 0);
        shippingCost += itemTotal * SHIPPING_COST_INC_INT;
      }
      return shippingCost;
    }),
    calcFees: useCallback((items, shipping = 0) => {
      let total = 0;
      items.forEach((item) => {
        total += Number(item.product.cost || item.cost) * item.quantity;
        if (item.branded_box) {
          total += item.quantity * BRANDED_BOX_COST;
        }
      });

      return Number(
        (total + shipping) * FEES_PERCENTAGE + FEES_CONSTANT
      ).toFixed(2);
    }),
    calcDiscount: useCallback((discount, subtotal) => {
      if (!discount.code) {
        return 0;
      }

      if (discount.type === DISCOUNT_TYPE_FIXED) {
        if (discount.value >= subtotal) {
          return subtotal;
        }
        return Number(discount.value);
      }

      const value = Number(discount.value);

      const discounttValue = Number(subtotal) * (value / 100);

      return discounttValue;
    }),
  };
}
