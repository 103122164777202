/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const myProductSlice = createSlice({
  name: "myProduct",
  initialState: {
    currentProductState: "ALL",
    // productsPublished: 0,
    // productsUnpublished: 0,
  },
  reducers: {
    updateProductState: (state, action) => {
      state.currentProductState = action.payload;
    },
  },
});

export const { updateProductState } = myProductSlice.actions;

export default myProductSlice.reducer;
