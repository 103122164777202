import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  matchRoutes,
  useNavigate,
} from "react-router-dom";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "components/Sidebar";
import LoadingScreen from "components/LoadingScreen";
import { ErrorBoundary } from "react-error-boundary";
import OnboardingView from "views/OnboardingView";
import { getUser, QUERY } from "api";
import { useQueryParam, usePageViews } from "hooks";
import { setProfile } from "state/profileSlice";
import {
  SUBSCRIPTION_STATUS_UNPAID,
  SUBSCRIPTION_STATUS_FAILED,
} from "constants/constants";

import ChangePlanModal from "components/Modals/ChangePlanModal";
import ErrorView from "views/ErrorView";
import UploadPaymentModal from "components/Modals/UploadPaymentModal";
import DunningModal from "components/Modals/DunningModal";
import PaymentUpdateResponseModal from "components/Modals/PaymentUpdateResponseModal/PaymentUpdateResponseModal";
import queryClient from "api/queryClient";
import CloseButton from "components/Notifications/CloseButton";
import SuspenseLoading from "components/SuspenseLoading";
import { useMediaQuery } from "react-responsive";
import HeaderBar from "components/HeaderBar";

import "react-toastify/dist/ReactToastify.css";
import { routes } from "routes";
import SidebarMobile from "components/SidebarMobile";
import styles from "./Main.module.scss";

export default function Main({ loggedIn, redirectPath = "/login", children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParam();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const onboardingComplete = useSelector(
    (state) => state.profile.shop.onboarding_complete
  );
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);
  const shop = useSelector((state) => state.profile.shop);
  const pageViews = usePageViews(useSelector((state) => state.profile.profile));

  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [updatePaymentModalOpen, setUpdatePaymentModalOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const { isLoading, isError, data, error } = useQuery(QUERY.getUser, getUser, {
    enabled: !!shop,
  });

  useEffect(() => {
    toast.clearWaitingQueue();
  }, []);

  useEffect(() => {
    if (
      shop.id &&
      shop.subscription_status === SUBSCRIPTION_STATUS_UNPAID &&
      queryParams.get("setup_intent") &&
      queryParams.get("dunning") &&
      shop.onboarding_complete
    ) {
      setUpdatePaymentModalOpen(true);
    }
  }, [shop]);

  if (!isLoggedIn && isLoading) {
    const url = new URL(`${window.location}`);
    const params = new URLSearchParams(url.search);
    params.set("redirect_url", location.pathname);
    navigate({
      pathname: redirectPath,
      search: params.toString(),
    });
  }

  if (isLoading || !shop.id) {
    return <LoadingScreen />;
  }

  // show onbaording if not complete
  if (!isLoading && !shop.onboarding_complete && shop.id) {
    return <OnboardingView />;
  }

  const getLocationName = () => {
    const route = matchRoutes(routes, location)[0];
    return route.route.name ? route.route.name : "App";
  };

  return (
    <div>
      <Helmet>
        <title>{getLocationName()} - Blanka</title>
        <meta
          name="description"
          content="Official app login page app for Shopify, Wix, WooCommerce and more"
        />
      </Helmet>
      {isMobile ? (
        <SidebarMobile onOpenDrawer={(open) => setDrawerOpen(open)} />
      ) : (
        <Sidebar onOpenDrawer={(open) => setDrawerOpen(open)} />
      )}
      <main className={drawerOpen ? styles.main : styles.mainClosed}>
        <Container maxWidth={`${isMobile ? "sm" : "xl"}`}>
          <ErrorBoundary
            FallbackComponent={ErrorView}
            onReset={() => {
              window.location.reload();
            }}
          >
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              preventDuplicates
              newestOnTop={false}
              closeOnClick
              limit={1}
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
              closeButton={CloseButton}
            />

            <React.Suspense fallback={<SuspenseLoading />}>
              <Outlet />
            </React.Suspense>

            <PaymentUpdateResponseModal
              open={updatePaymentModalOpen}
              handleClose={() => setUpdatePaymentModalOpen(false)}
            />

            <ChangePlanModal
              handleClose={() => setUpgradeModalOpen(false)}
              open={upgradeModalOpen}
            />

            <DunningModal
              open={
                (shop.subscription_status === SUBSCRIPTION_STATUS_FAILED ||
                  shop.subscription_status === SUBSCRIPTION_STATUS_UNPAID) &&
                !updatePaymentModalOpen
              }
              allowClose={false}
              handleClose={() => queryClient.refetchQueries([QUERY.getUser])}
            />
          </ErrorBoundary>
        </Container>
      </main>
    </div>
  );
}

Main.propTypes = {};

Main.defaultProps = {};
