import React, { useState } from "react";
import { useScrollPosition } from "hooks/useScrollPosition";
import Text from "components/Text";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  PLAN_FREE_ID,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
} from "constants/constants";
import { useMediaQuery } from "react-responsive";
import styles from "./PageHeader.module.scss";

export default function PageHeader({ title, children }) {
  const scrollPosition = useScrollPosition();
  const shop = useSelector((state) => state.profile.shop);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <Grid
      container
      className={`${!isMobile && styles.pageHeaderContainer} ${
        isMobile &&
        // shop.custom_product_status !== CUSTOM_PRODUCT_STATUS_COMPLETE
        // ? styles.pageHeaderContainer
        styles.minimumnPageHeaderContainer
      } 
      ${isMobile && scrollPosition > 0 && styles.dropShadow}
      `}
    >
      <Text className="text__page-header">{title}</Text>
      <div className={styles.actionContainer}>{children}</div>
    </Grid>
  );
}

PageHeader.propTypes = {
  // title: PropTypes.string.isRequired,
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  title: "",
};
