import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import styles from "./AccountNav.module.scss";

function AccountNav(props) {
  return (
    <Grid className="paper " container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className="nav-div">
          <NavLink
            to="/profile"
            end
            className={({ isActive }) =>
              isActive ? styles.navItemFirstActive : styles.navItemFirst
            }
          >
            Plan
          </NavLink>
          {"  |  "}
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.navItemActive : styles.navItem
            }
            to="/profile/billing"
          >
            Payment
          </NavLink>
          {"  |  "}
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.navItemActive : styles.navItem
            }
            to="/profile/password"
          >
            Password
          </NavLink>
          {"  |  "}
          <NavLink
            to="/profile/connect"
            className={({ isActive }) =>
              isActive ? styles.navItemActive : styles.navItem
            }
          >
            My Store
          </NavLink>
        </div>
      </Grid>
    </Grid>
  );
}

export default AccountNav;
