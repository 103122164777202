import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import AddPaymentMethodForm from "components/AddPaymentMethodForm";
import { createSetupIntent, updateShopPayment, updateShop, getUser } from "api";

import {
  PLAN_INFO,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_VIP_ID,
} from "constants/constants";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";

import { STRIPE_THEME } from "theme";

import styles from "./CheckoutAddPayment.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

const RETURN_URL = `${String(
  process.env.REACT_APP_DOMAIN ||
    `${window.location.protocol}//${window.location.host}`
)}/checkout/payment`;

export default function CheckoutAddPayment() {
  const [clientSecret, setClientSecret] = useState();
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const initSetupIntent = async () => {
      try {
        // await getUser();
        const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
        setClientSecret(response.data.client_secret);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    initSetupIntent();
  }, []);

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  const handleAddPaymentMethod = async (paymentId) => {
    await updateShopPayment(paymentId);
  };
  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}
      <div className={styles.stripeContainer}>
        {clientSecret && !loading && (
          <Elements options={options} stripe={stripePromise}>
            <AddPaymentMethodForm
              handleAddPaymentMethod={handleAddPaymentMethod}
              submitButtonText="Save Payment"
              showFreeTrialText={false}
              returnUrl={RETURN_URL}
              handleNextStep={(e) => {}}
              successMessageText="Please wait while we update your payment information"
            />
          </Elements>
        )}
      </div>
    </div>
  );
}
