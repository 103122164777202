import { store } from "state/store";
import { setShop, setProfile } from "state/profileSlice";
import apiService from "api/apiService";

export const createUser = (user) =>
  apiService.post(`/users/register/`, {
    ...user,
  });

export const getUser = (user) =>
  apiService.get("/users/me/").then((res) => {
    store.dispatch(setProfile(res.data));
    return res;
  });

export const updateShop = (id, data) =>
  apiService.patch(`/shop/${id}/`, data).then((res) => {
    store.dispatch(setShop(res.data));
    return res;
  });

export const updateShopPayment = (data) =>
  apiService.post("/shop/update_payment/", { payment_id: data });

export const updatePlan = (data) =>
  apiService.post("/shop/update_plan/", { plan_id: data });

export const downgradeToFreePlan = () =>
  apiService.post("/shop/downgrade-to-free/", {});

export const connectStore = (data) =>
  apiService.post(`/shop/connect-shopify-store/`, data);

export const connectWoocommerceStore = (data) =>
  apiService.post(`/shop/connect-woocommerce-store/`, data);

export const connectWixStore = (data) =>
  apiService.post(`/shop/connect-wix-store/`, data);

export const newVisualizationRequest = (data) =>
  apiService.post(`/shop/new-visualization/`, data);

export const uploadShopLogo = (id, data) => {
  const bodyFormData = new FormData();
  if (data.logo) {
    bodyFormData.append("logo", data.logo);
  }
  return apiService.patch(`/shop/${id}/`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export function updateShopLogo(id, logo) {
  const bodyFormData = new FormData();
  bodyFormData.append("logo", logo);
  bodyFormData.append("logo_uploaded", true);

  return apiService.patch(`/shop/${id}/`, bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
