import React from "react";
import Text from "components/Text";
import { Link } from "react-router-dom";
import OnboardingWrapper from "../OnboardingWrapper";
import styles from "./OnboardingStepOne.module.scss";

import SignUpForm from "./SignUpForm";

export default function OnboardingStepOne() {
  return (
    <OnboardingWrapper>
      <div className={styles.onboardingStepOne}>
        <div className={styles.signUpText}>
          <Text fontSize={24} className="text--bold text--blue">
            Sign up for free!
          </Text>

          <Link
            to="/login"
            className={`${styles.textLogin} text--nunito text--info`}
          >
            Already have an account?{" "}
            <span className={styles.redText}>Log in</span>
          </Link>
        </div>

        <SignUpForm />
      </div>
    </OnboardingWrapper>
  );
}
