import React, { useState } from "react";
import { useSelector } from "react-redux";
import Text from "components/Text";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "components/Button";
import { useMediaQuery } from "react-responsive";
import ChangePlanModal from "components/Modals/ChangePlanModal";
import styles from "./UpgradeBlock.module.scss";

export default function UpgradeBlock() {
  const [modalOpen, setModalOpen] = useState(false);
  const shop = useSelector((state) => state.profile.shop);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <Card className={styles.upgradeBlock}>
      <CardContent className={styles.content}>
        <Text color="white" variant="h3" fontSize={18} className={styles.text}>
          Start selling branded products today!
        </Text>
      </CardContent>
      <CardActions className={styles.actions}>
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => setModalOpen(true)}
          className={`text--spacing-0 ${styles.button}`}
          hasChevron
        >
          {shop.has_trial ? "TRY FOR FREE" : "CHANGE PLAN"}
        </Button>
      </CardActions>
      <ChangePlanModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </Card>
  );
}
