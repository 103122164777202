import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ProductTag from "components/Labels/ProductTag";
import {
  PRODUCT_TYPE_UNBRANDED,
  BRANDED_BOX_COST,
  PRODUCT_TYPE_CUSTOM,
  CART_TYPE_INVENTORY,
} from "constants/constants";
import styles from "./CartItem.module.scss";

function CartItem({ item, cartType, handleUpdate, handleRemoveItem }) {
  const [amount, setAmount] = useState();
  const cart = useSelector((state) => state.cart);
  const [cost, setCost] = useState(
    (item.product.cost * item.quantity).toFixed(2)
  );

  const [isChecked, setIsChecked] = useState(
    item.product.branded_box_available &&
      item.product.product_type === PRODUCT_TYPE_CUSTOM
  );

  useEffect(() => {
    setIsChecked(item.branded_box);
  }, [item]);

  useEffect(() => {
    const updatedProduct = { ...item };

    if (
      item.product.branded_box_available &&
      cart.type === CART_TYPE_INVENTORY
    ) {
      updatedProduct.branded_box = true;
    } else {
      updatedProduct.branded_box = false;
    }

    handleUpdate(updatedProduct, item.quantity);
  }, []);

  const handleBrandedBoxCheck = (event) => {
    setIsChecked(event.target.checked);
    const updatedProduct = { ...item };
    updatedProduct.branded_box = event.target.checked;
    handleUpdate(updatedProduct, item.quantity);
  };

  const getTotal = () => {
    let total = item.quantity * item.product.cost;

    if (isChecked) {
      total += item.quantity * BRANDED_BOX_COST;
    }

    return Number(total);
  };

  const brandedBoxCost = () => {
    if (isChecked) {
      return item.quantity * BRANDED_BOX_COST;
    }
    return 0;
  };

  return (
    <TableRow>
      <TableCell align="center" className={styles.imageCell}>
        <div className={styles.productImage}>
          <img className="image" src={item.product.image} alt="Product" />
        </div>
      </TableCell>
      <TableCell className="name-tag-cell ">
        {item.product.name}{" "}
        {item.product.color_name ? ` - ${item.product.color_name}` : ""}
        <ProductTag productType={item.product.product_type} />
      </TableCell>
      <TableCell>{item.sku || item.product.sku}</TableCell>

      {cartType === CART_TYPE_INVENTORY && (
        <TableCell>
          {item.product.branded_box_available && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!item.product.branded_box_available}
                    checked={isChecked}
                    onChange={(event) => handleBrandedBoxCheck(event, item.id)}
                  />
                }
                label="Add Branded Boxes $0.40/Ea"
              />
            </FormGroup>
          )}
        </TableCell>
      )}

      <TableCell align="right" style={{ width: "100px" }} size="small">
        <FormControl fullWidth className={styles.margin}>
          <Input
            id="standard-adornment-amount"
            value={item.quantity}
            type="number"
            onChange={(e) => handleUpdate(item, e.target.value)}
            startAdornment={<InputAdornment position="start" />}
          />
        </FormControl>
      </TableCell>
      <TableCell align="right">
        $
        {(
          Number(item.product.cost) + (isChecked ? BRANDED_BOX_COST : 0)
        ).toFixed(2)}
      </TableCell>

      <TableCell align="right">${getTotal().toFixed(2)}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="delete"
          onClick={(e) => handleRemoveItem(item.id)}
        >
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default CartItem;
