/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { useMediaQuery } from "react-responsive";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Text from "components/Text";
import { HelpIcon } from "components/Icons";
import AppBar from "components/AppBar";
import { NavLink } from "react-router-dom";
import { routes } from "routes";
import blankaLogo from "assets/images/logo.png";
import { PLAN_FREE_ID } from "constants/constants";
import UpgradeBlock from "./components/UpgradeBlock";

import styles from "./Sidebar.module.scss";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 6px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ onOpenDrawer }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const shop = useSelector((state) => state.profile.shop);

  const handleMediaQueryChange = (matches) => {
    // matches will be true or false based on the value for the media query
    setOpen(matches);
    onOpenDrawer(matches);
  };
  const isDesktopOrLaptop = useMediaQuery(
    { minWidth: 720 },
    undefined,
    handleMediaQueryChange
  );

  const handleDrawerOpen = () => {
    // setOpen(true);
    // onOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    // setOpen(false);
    // onOpenDrawer(false);
  };

  return (
    <Box sx={{ display: "flex" }} className="sidebar">
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />

      <Drawer variant="permanent" open={open} className={styles.drawer}>
        <DrawerHeader className={styles.drawerHead}>
          <img src={blankaLogo} alt="blanka" className={styles.blankaLogo} />
          <IconButton onClick={handleDrawerClose} style={{ display: "none" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <List className={styles.submenu}>
          {routes
            .filter((route) => route.menu)
            .map((route, index) => (
              <div key={index}>
                {route.sectionTitle && (
                  <Text
                    fontSize={9}
                    variant="body1"
                    color="gray"
                    className="text--upper text--spacing-2-4 mt-25 ml-5"
                  >
                    {route.sectionTitle}
                  </Text>
                )}
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "navItem activeItem" : "navItem"
                  }
                  to={route.path}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <ListItem button key={route.name}>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.name} />
                  </ListItem>
                </NavLink>
                {route.hasDividerBottom && <Divider />}
              </div>
            ))}

          <NavLink
            className="navItem"
            to="#"
            style={{ textDecoration: "none" }}
            key={99}
            onClick={() => {
              window.open("http://faq.blankabrand.com/");
            }}
          >
            <ListItem button key="help">
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </NavLink>
        </List>
        {shop.plan === PLAN_FREE_ID && <UpgradeBlock />}
      </Drawer>
    </Box>
  );
}
