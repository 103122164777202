import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import Text from "components/Text";
import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import { CART_TYPE_SAMPLE, CART_TYPE_INVENTORY } from "constants/constants";
import { removeCart, updateCart } from "state/cartSlice";
import PageHeader from "components/PageHeader";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";

import styles from "./CartEmpty.module.scss";

function CartEmpty(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  return (
    <div className="cart-template">
      <PageHeader title="My Shopping Cart" />
      <Card variant="outlined" className={styles.card}>
        <Text variant="h3" fontSize={24} className="mb-10">
          Your cart is empty
        </Text>
        <Link to="/" className={styles.overide}>
          <Button variant="contained" color="primary">
            Continue Browsing
          </Button>
        </Link>
      </Card>
    </div>
  );
}

export default CartEmpty;
