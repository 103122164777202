import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Checkbox from "@mui/material/Checkbox";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import Text from "components/Text";
import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  CART_TYPE_SAMPLE,
  CART_TYPE_INVENTORY,
  CUSTOM_PRODUCT_STATUS_COMPLETE,
} from "constants/constants";
import { removeCart, updateCart } from "state/cartSlice";
import PageHeader from "components/PageHeader";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import { usePageViews } from "hooks";
import ConfirmUnbrandedOrderModal from "../../components/Modals/ConfirmUnbrandedOrderModal";
import CartItem from "./components/CartItem";
import CartTotals from "./components/CartTotals";
import CartEmpty from "./components/CartEmpty";
import styles from "./CartView.module.scss";

function CartView(props) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const shop = useSelector((state) => state.profile.shop);

  const handleContinue = () => {
    navigate("/checkout/shipping");
  };

  const onConfirm = () => {
    setIsOpen(false);
    handleContinue();
  };

  const handleSelectAll = (selected) => {
    cart?.items.map((item) => {
      if (item.product.branded_box_available) {
        dispatch(
          updateCart({
            item: { ...item, branded_box: selected },
            quantity: item.quantity,
          })
        );
      }
    });
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const isSelectAll = () => {
    let isAll = true;
    cart?.items.map((item) => {
      if (!item.branded_box && item.product.branded_box_available) {
        isAll = false;
      }
    });
    return isAll;
  };

  const handleUpdate = (item, quantity) => {
    if (!item.id) {
      return;
    }

    if (quantity > 0) {
      dispatch(updateCart({ item, quantity }));
    }
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeCart(itemId));
  };

  const handleContinueToCheckout = () => {
    if (
      shop.custom_product_status === CUSTOM_PRODUCT_STATUS_COMPLETE &&
      cart.type === CART_TYPE_SAMPLE
    ) {
      setIsOpen(true);
    } else {
      handleContinue();
    }
  };

  if (cart.items.length === 0) {
    return <CartEmpty />;
  }
  return (
    <div className={`cart-template ${styles.cartView}`}>
      <PageHeader title="My Shopping Cart" />
      <Card variant="outlined" className={styles.card}>
        <TableContainer>
          <Table aria-label="">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Product</TableCell>
                <TableCell>Sku</TableCell>
                {cart.type === CART_TYPE_INVENTORY && (
                  <TableCell>
                    Branded Boxes
                    <div>
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={isSelectAll()}
                        onChange={(event) => {
                          handleSelectAll(event.target.checked);
                        }}
                      />
                      Select All
                    </div>
                  </TableCell>
                )}
                <TableCell>Quantity</TableCell>
                <TableCell align="right">Unit Cost (USD)</TableCell>
                <TableCell align="right">Total Cost (USD)</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.items.map((item) => (
                <CartItem
                  item={item}
                  handleUpdate={handleUpdate}
                  handleRemoveItem={handleRemoveItem}
                  cartType={cart.type}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <div className={styles.footerContainer}>
        <CheckoutFooter />
        {cart.type === CART_TYPE_SAMPLE && (
          <Text fontSize="12" color="peach" className="mr-16 text--bold">
            NOTE: ALL SAMPLE ORDERS ARE SHIPPED WITH UNBRANDED PRODUCTS
          </Text>
        )}
      </div>
      <CartTotals items={cart.items} />

      <div className={styles.actionContainer}>
        <Button
          onClick={handleContinueToCheckout}
          variant="outlined"
          color="primary"
          className="button button--primary"
        >
          Continue To Shipping{" "}
        </Button>
        <Text
          style={{ display: "block", marginTop: "5px" }}
          fontSize={10}
          className="text--upper"
          color="gray"
        >
          shipping calculated at checkout
        </Text>
      </div>

      {isOpen && (
        <ConfirmUnbrandedOrderModal
          open={isOpen}
          handleClose={onClose}
          handleConfirm={onConfirm}
        />
      )}
    </div>
  );
}

export default CartView;
