import React from "react";
import PropTypes from "prop-types";

import Button from "components/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  SCALE_PLAN,
  PLAN_PRICING_TABLE_INFO,
  VIP_PLAN,
} from "constants/constants";
import { CrossMarkIcon, CheckMarkIcon } from "components/Icons";
import Text from "../Text";
import Ribbon from "../Labels/Ribbon";

import styles from "./PlanTableCard.module.scss";

export default function PlanTableCard({
  planId,
  activePlan,
  hasTrial,
  onChange,
  handleClick,
  loading,
  alt,
}) {
  const getButtonText = (planId) => {
    if (activePlan === planId) {
      return "CURRENT";
    }
    if (hasTrial) {
      return "START FREE TRIAL";
    }
    return "GET STARTED";
  };
  const useRibbon = () => {
    if (PLAN_PRICING_TABLE_INFO[planId].name === SCALE_PLAN) {
      return true;
    }
    return null;
  };

  return (
    <div>
      <Card
        className={`${styles.planCard}
        ${alt && styles.planCardCentered}
        ${PLAN_PRICING_TABLE_INFO[planId].id === 0 && styles.featureColumn}
        ${
          PLAN_PRICING_TABLE_INFO[planId].name === SCALE_PLAN &&
          styles.scaleCard
        }
        ${PLAN_PRICING_TABLE_INFO[planId].name === VIP_PLAN && styles.isVIP}`}
        data-testid="planCard"
      >
        {useRibbon() && <Ribbon>Most Popular</Ribbon>}

        <Text
          fontSize={13}
          variant="h4"
          className={`text--upper 
          ${styles.planType}
          ${
            PLAN_PRICING_TABLE_INFO[planId].name === SCALE_PLAN &&
            styles.scaleCardBtn
          }            
            `}
        >
          {PLAN_PRICING_TABLE_INFO[planId].name}
        </Text>

        <CardContent className={styles.cardContent}>
          <div
            style={
              PLAN_PRICING_TABLE_INFO[planId].name === SCALE_PLAN
                ? { paddingTop: "0px" }
                : {}
            }
            className={styles.planDetails}
          >
            <ul className={planId === 4 && styles.isVIP}>
              {PLAN_PRICING_TABLE_INFO[planId].features_list.map((feature) => {
                if (feature.feature === "Cross") {
                  return (
                    <li key={feature.feature}>
                      <CrossMarkIcon />
                    </li>
                  );
                }
                if (feature.feature === "Check") {
                  return (
                    <li key={feature.feature}>
                      <CheckMarkIcon />
                    </li>
                  );
                }
                return <li key={feature.feature}>{feature.feature}</li>;
              })}
            </ul>
          </div>
        </CardContent>
        {PLAN_PRICING_TABLE_INFO[planId].name !== null && (
          <CardActions className={styles.actionContainer}>
            <Button
              loading={loading}
              onClick={(e) => handleClick(planId)}
              color="primary"
              variant="contained"
              disabled={activePlan === planId}
              hasChevron
              size="small"
              className={styles.cta}
            >
              {getButtonText(planId)}
            </Button>
          </CardActions>
        )}
        <Text
          fontSize={28}
          variant="h4"
          className={`${styles.textPrice} ${
            PLAN_PRICING_TABLE_INFO[planId].name === SCALE_PLAN &&
            styles.centerText
          }`}
        >
          {PLAN_PRICING_TABLE_INFO[planId].id === 1 ||
          PLAN_PRICING_TABLE_INFO[planId].id === 0
            ? ""
            : `Then, $${PLAN_PRICING_TABLE_INFO[planId].price}/month`}
        </Text>
      </Card>
    </div>
  );
}

PlanTableCard.propTypes = {
  planId: PropTypes.number,
  onChange: PropTypes.func,
};

PlanTableCard.defaultProps = {
  planId: 1,
  onChange: () => {},
};
