import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import Button from "components/Button";
import Card from "@mui/material/Card";
import Text from "components/Text";
import TextField from "@mui/material/TextField";
import PageHeader from "components/PageHeader";
import AccountNav from "components/AccountNav";
import { changePassword } from "api";
import { GenericSuccessNotification } from "components/Notifications";
import { useNavigate } from "react-router-dom";

import PasswordStrengthBar from "components/PasswordStrengthBar";
import styles from "./AccountPasswordUpdateView.module.scss";

function AccountPasswordUpdateView(props) {
  const navigate = useNavigate();
  const updatePasswordMutation = useMutation(changePassword);
  const profile = useSelector((state) => state.profile.profile);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [password, setPassword] = useState({
    old_password: null,
    new_password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );

  const handleOldPasswordChange = (event) => {
    setPassword({ ...password, old_password: event.target.value });
    setError("");
    setMessage("");
  };

  const handleNewPasswordChange = (event) => {
    setMessage("");
    setPassword({ ...password, new_password: event.target.value });
  };

  const handleSubmit = () => {
    setLoading(true);
    updatePasswordMutation.mutate(
      {
        old_password: password.old_password,
        new_password: password.new_password,
      },
      {
        onSuccess: (response) => {
          setLoading(false);
          if (response?.status === 200) {
            toast.success(
              <GenericSuccessNotification text="Your password has been updated" />
            );
            navigate("/profile/password");
          }
        },
        onError: (err) => {
          setLoading(false);
          setPassword({
            old_password: "",
            new_password: "",
          });

          setMessage(
            `Oops! Please ensure your new password meets the strength requirements and your old password is correct`
          );
        },
      }
    );
  };

  const isValid = () => {
    if (
      !profile?.has_usable_password &&
      password.new_password &&
      password.new_password.length > 6
    ) {
      return true;
    }

    if (
      password.old_password &&
      password.new_password &&
      password.new_password.length > 6
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="template-profile-password root">
      <PageHeader title="My Account" />
      <AccountNav />

      <Card className="card" variant="outlined">
        <div className="header-text-container">
          <Text
            className="text__title text--georgia text--bold"
            color="medium-gray"
          >
            Password
          </Text>

          <Text
            className="text__secure-message text--nunito"
            variant="body1"
            color="gray"
          >
            All passwords are secure and encrypted. All passwords must meet the
            following requirements:
            <ul className="pl-10">
              <li className="text__secure-message text--nunito">
                Your password can&apos;t be too similar to your other personal
                information.
              </li>
              <li className="text__secure-message text--nunito">
                Your password must contain at least 8 characters.
              </li>
              <li className="text__secure-message text--nunito">
                Your password can’t be a commonly used password or an old
                password.
              </li>
              <li className="text__secure-message text--nunito">
                Your password can’t be entirely numeric.
              </li>
            </ul>
          </Text>

          <div>
            {profile && profile.has_usable_password && (
              <Text className="text__current-password text--nunito text--upper">
                Old Password
              </Text>
            )}

            <form>
              {profile?.has_usable_password && (
                <TextField
                  className="form-text-field text--nunito"
                  variant="outlined"
                  required
                  InputLabelProps={{ shrink: true }}
                  id="old_password"
                  value={password.old_password}
                  type="password"
                  onChange={handleOldPasswordChange}
                />
              )}

              <Text className="text__current-password text--nunito text--upper">
                New Password
              </Text>

              <PasswordStrengthBar
                password={password.new_password}
                width="300px"
                margin="10px 0 0 0"
                handleStrengthChange={(strength) =>
                  setPasswordStrength(strength)
                }
              />

              <TextField
                className="form-text-field text--nunito"
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
                id="confirmPassword"
                value={password.new_password}
                type="password"
                onChange={handleNewPasswordChange}
              />
            </form>

            {error ? <p className="text--error">{error}</p> : <p> </p>}
            {message && <p className="text--error">{message}</p>}
          </div>
        </div>
      </Card>
      <div className={styles.submitContainer}>
        <Button
          disabled={!isValid() || passwordStrength < 3}
          loading={loading}
          onClick={() => handleSubmit()}
          color="primary"
          variant="contained"
          className="button--primary mt-15"
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default AccountPasswordUpdateView;
