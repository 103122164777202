export const FEES_PERCENTAGE = 0.029;
export const FEES_CONSTANT = 0.3;

export const SHIPPING_COST_BASE_US = 6;
export const SHIPPING_COST_INC_US = 2;

export const SHIPPING_COST_BASE_INT = 9;
export const SHIPPING_COST_INC_INT = 3;

export const BRANDED_BOX_COST = 0.4;

export const PLAN_PRICING_TABLE_INFO = {
  0: {
    id: 0,
    price: 0,
    name: null,
    features_list: [
      { feature: "Free product mock-ups" },
      { feature: "Unbranded products" },
      { feature: "Branded products" },
      { feature: "Premium products" },
      { feature: "Free product warehousing" },
      { feature: "Dedicated account manager" },
      { feature: "Dedicated phone support" },
      { feature: "Branded package inserts" },
    ],
  },
  1: {
    id: 1,
    price: 0,
    name: "FREE",
    features_list: [
      { feature: "Cross" },
      { feature: "10" },
      { feature: "" },
      { feature: "" },
      { feature: "" },
      { feature: "" },
      { feature: "" },
      { feature: "" },
    ],
  },
  2: {
    id: 2,
    price: 29,
    name: "GROWTH",
    features_list: [
      { feature: "Check" },
      { feature: "Unlimited" },
      { feature: "10" },
      { feature: "Check" },
      { feature: "Check" },
      { feature: "" },
      { feature: "" },
      { feature: "" },
    ],
  },
  3: {
    id: 3,
    price: 59,
    name: "SCALE",
    features_list: [
      { feature: "Check" },
      { feature: "Unlimited" },
      { feature: "Unlimited" },
      { feature: "Check" },
      { feature: "Check" },
      { feature: "" },
      { feature: "" },
      { feature: "" },
    ],
  },
  4: {
    id: 4,
    price: 99,
    name: "VIP",
    features_list: [
      { feature: "Check" },
      { feature: "Unlimited" },
      { feature: "Unlimited" },
      { feature: "Check" },
      { feature: "Check" },
      { feature: "Check" },
      { feature: "Check" },
      { feature: "Check" },
    ],
  },
};

export const PLAN_PRICING_MOBILE_TABLE_INFO = {
  1: {
    id: 1,
    price: null,
    name: "FREE",
    features_list: [
      {
        provided: "Free product mock-ups",
        feature: "Check",
      },
      {
        provided: "Unbranded products",
        feature: "10",
      },
    ],
  },
  2: {
    id: 2,
    price: 29,
    name: "GROWTH",
    features_list: [
      {
        provided: "Free product mock-ups",
        feature: "Check",
      },
      {
        provided: "Unbranded products",
        feature: "Unlimited",
      },
      {
        provided: "Branded products",
        feature: "10",
      },
      {
        provided: "Premium products",
        feature: "Check",
      },
      {
        provided: "Free product warehousing",
        feature: "Check",
      },
    ],
  },
  3: {
    id: 3,
    price: 59,
    name: "SCALE",
    features_list: [
      {
        provided: "Free product mock-ups",
        feature: "Check",
      },
      {
        provided: "Unbranded products",
        feature: "Unlimited",
      },
      {
        provided: "Branded products",
        feature: "Unlimited",
      },
      {
        provided: "Premium products",
        feature: "Check",
      },
      {
        provided: "Free product warehousing",
        feature: "Check",
      },
    ],
  },
  4: {
    id: 4,
    price: 99,
    name: "VIP",
    features_list: [
      {
        provided: "Free product mock-ups",
        feature: "Check",
      },
      {
        provided: "Unbranded products",
        feature: "Unlimited",
      },
      {
        provided: "Branded products",
        feature: "Unlimited",
      },
      {
        provided: "Premium products",
        feature: "Check",
      },
      {
        provided: "Free product warehousing",
        feature: "Check",
      },
      {
        provided: "Dedicated account manager",
        feature: "Check",
      },
      {
        provided: "Dedicated phone support",
        feature: "Check",
      },
      {
        provided: "Branded package inserts",
        feature: "Check",
      },
    ],
  },
};

export const PLAN_INFO = {
  1: {
    id: 1,
    price: 0,
    name: "FREE",
    features_list: [{ feature: "Create 10 unbranded products" }],
  },
  2: {
    id: 2,
    price: 29,
    name: "GROWTH",
    features_list: [
      { feature: "Create 10 branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
    ],
  },
  3: {
    id: 3,
    price: 59,
    name: "SCALE",
    features_list: [
      { feature: "Create unlimited branded products" },
      { feature: "Free product mock-ups" },
      { feature: "Access to Premium products" },
    ],
  },
  4: {
    id: 4,
    price: 99,
    name: "VIP",
    features_list: [
      { feature: "Everything in Scale Plan + MORE" },
      { feature: "Dedicated account manager" },
      { feature: "Dedicated phone support" },
      { feature: "Branded inserts in orders" },
      { feature: "Access to Premium products" },
    ],
  },
};

export const CART_TYPE_SAMPLE = "SAMPLE";
export const CART_TYPE_INVENTORY = "INVENTORY";

export const UNFULFILLED = "UNFULFILLED";
export const FULFILLED = "FULFILLED";
export const ORDERED = "ORDERED";
export const SHIPPED = "SHIPPED";
export const CANCELLED = "CANCELLED";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_REQUIRED = "PAYMENT_REQUIRED";

export const TRY_FOR_FREE = "TRY FOR FREE";
export const CHANGE_PLAN = "CHANGE PLAN";
export const CURRENT = "CURRENT";

export const SCALE_PLAN = "SCALE";
export const GROWTH_PLAN = "GROWTH";
export const FREE_PLAN = "FREE";
export const VIP_PLAN = "VIP";

export const DISCOUNT_TYPE_FIXED = "FIXED";
export const DISCOUNT_TYPE_PERCENTAGE = "PERCENTAGE";

export const PLAN_FREE_ID = 1;
export const PLAN_GROWTH_ID = 2;
export const PLAN_SCALE_ID = 3;
export const PLAN_VIP_ID = 4;

export const PRODUCT_TYPE_ALL = "ALL";
export const PRODUCT_TYPE_UNBRANDED = "BLANK";
export const PRODUCT_TYPE_CUSTOM = "CUSTOM";

export const PRODUCT_STATE_ALL = "ALL";
export const PRODUCT_STATE_PUBLISHED = "PUBLISHED";
export const PRODUCT_STATE_UNPUBLISHED = "UNPUBLISHED";

export const PRODUCT_CATEGORY_SAMPLE_KIT_ID = 6;

export const CUSTOM_PRODUCT_STATUS_NONE = "NONE";
export const CUSTOM_PRODUCT_STATUS_IN_PROGRESS = "IN_PROGRESS";
export const CUSTOM_PRODUCT_STATUS_COMPLETE = "CREATED";

export const PRODUCT_BLANKA_BRAND = "BLANKA";
export const PRODUCT_TYPE_PRIVATE_LABEL = "SUPPLIER";

export const SHOP_ONBOARDING_STANDALONE = "STANDALONE";
export const SHOP_ONBOARDING_SHOPIFY = "SHOPIFY";

export const SUBSCRIPTION_STATUS_FAILED = "past_due";
export const SUBSCRIPTION_STATUS_UNPAID = "unpaid";
export const SUBSCRIPTION_STATUS_TRIALING = "trialing";

export const ORDER_STATUS_ALL = "ALL";
export const ORDER_STATUS_PAYMENT_REQUIRED = "PAYMENT_REQUIRED";
export const ORDER_STATUS_ORDERED = "ORDERED";
export const ORDER_STATUS_UNFULFILLED = "UNFULFILLED";
export const ORDER_STATUS_FULFILLED = "SHIPPED";
export const ORDER_STATUS_CANCELLED = "CANCELLED";

// Integrationt ype
export const SHOP_INTEGRATION_TYPE_SHOPIFY = "SHOPIFY";
export const SHOP_INTEGRATION_TYPE_WOOCOMMERCE = "WOOCOMMERCE";
export const SHOP_INTEGRATION_TYPE_WIX = "WIX";

export const BRAND_PLATE_COST = {
  ONE_COLOR: 149,
  TWO_COLOR: 199,
  MULTI_COLOR: 249,
};

export const ERROR_PLAN_EXCEEDED = "ERROR_PLAN_EXCEEDED";
// export const ERROR_GROWWTH_PLAN_EXCEEDED = 'ERROR_GROWWTH_PLAN_EXCEEDED';
