import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useQueryParam } from "hooks";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import { updatePlan, createSetupIntent, updateShopPayment, QUERY } from "api";

import {
  PLAN_INFO,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_VIP_ID,
  PLAN_FREE_ID,
  SHOP_ONBOARDING_SHOPIFY,
  TRY_FOR_FREE,
} from "constants/constants";
import queryClient from "api/queryClient";
import { useLocation } from "react-router-dom";
import { GenericSuccessNotification } from "components/Notifications";
import Text from "components/Text";
import UploadPaymentModal from "components/Modals/UploadPaymentModal";
import { STRIPE_THEME } from "theme";
import UpdatePaymentMethodModalForm from "components/UpdatePaymentMethodModalForm";
import PlanTable from "../../PlanTable";
import styles from "./ChangePlanModal.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

export default function ChangePlanModal({
  props,
  open,
  handleClose,
  isFreePlanLimit,
  title,
  freePlanLimitSubText,
}) {
  const queryParams = useQueryParam();
  const location = useLocation();
  const changePlanMutation = useMutation(updatePlan);
  const [loading, setLoading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const shop = useSelector((state) => state.profile.shop);
  const [uploadPaymentModal, setUploadPaymentModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState();
  const [responseError, setResponseError] = useState();
  const [isResponse, setIsResponse] = useState(false);
  const [pendingPlanId, setPendingPlanId] = useState();
  const [paymentRequired, setPaymentRequired] = useState(
    shop.has_payment_method
  );
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const setSetupIntent = async (id) => {
    try {
      // await getUser();
      setStripeLoading(true);
      const response = await createSetupIntent(PLAN_INFO[id].price);
      setClientSecret(response.data.client_secret);
      setStripeLoading(false);
    } catch (e) {
      setStripeLoading(false);
    }
  };

  const handleChangePlan = async (id, bypass = false) => {
    // bypass is used to get a round a race condition with stripe returning
    // the payment save info

    const planChangeLabel = id > shop.plan_id ? "upgrade" : "downgrade";

    if (!shop.has_payment_method && !bypass && id !== PLAN_FREE_ID) {
      setPendingPlanId(id);
      setShowPaymentForm(true);
      await setSetupIntent(id);
      return;
    }
    setLoading(true);
    changePlanMutation.mutate(id, {
      onSuccess: (response) => {
        if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
          window.location.href = response.data.confirmation_url;
          return;
        }

        queryClient.refetchQueries(["get-user"]).then(() => {
          setLoading(false);
          toast.success("Success! Your account has been updated.");
          handleClose();
          setShowPaymentForm(false);
          setIsResponse(false);
        });

        window.gtag("event", `plan_${planChangeLabel}`, {
          event_category: "user",
          screen_name: `change_plan_modal - ${location.pathname} - PAYWALL: ${
            isFreePlanLimit ? "Y" : "N"
          }`,
          value: `${PLAN_INFO[id].name}`,
        });
      },
      onError: (err) => {
        setLoading(false);
      },
    });
  };

  const handleAddPaymentMethod = async (paymentId) => {
    updateShopPayment(paymentId).then((res) => {
      if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = res.data.confirmation_url;
      }
      handleClose();
      toast.success("Success! Your account has been updated.");
      setShowPaymentForm(false);
      setIsResponse(false);
    });
  };

  useEffect(() => {
    if (
      shop.id &&
      queryParams.get("setup_intent") &&
      queryParams.get("plan_id")
    ) {
      setIsResponse(true);
      setPendingPlanId(queryParams.get("plan_id"));
      setClientSecret(queryParams.get("setup_intent_client_secret"));
      setStripeLoading(false);
      setShowPaymentForm(true);
    }
  }, []);

  const handlePlanAutoUpgrade = async () => {
    handleChangePlan(queryParams.get("plan_id"), true).then((res) => {
      if (shop.shop_signup_type === SHOP_ONBOARDING_SHOPIFY) {
        window.location.href = res.data.confirmation_url;
      }
    });
  };

  const getButtonText = () => {
    if (shop.plan === 1) {
      return "CURRENT";
    }
    if (shop.has_trial) {
      return "TRY FOR FREE";
    }
    return "CHANGE PLAN";
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  return (
    <ModalWrapper
      handleClose={() => {
        handleClose();
        setShowPaymentForm(false);
      }}
      isOpen={open}
      data-testid="FreePlanLimitModal"
    >
      <div>
        {!showPaymentForm && !isResponse && (
          <>
            {isFreePlanLimit && (
              <Text
                className="text--bold text__header"
                color="medium-gray"
                variant="h2"
                fontSize={21}
              >
                {title ||
                  "Oops! You've reached your plan limit. Select any paid plan below to continue."}
              </Text>
            )}

            {isFreePlanLimit ? (
              <Text className="text__body text--nunito">
                {freePlanLimitSubText ||
                  "Select any paid plan below to brand products."}
              </Text>
            ) : (
              <Text color="medium-gray" fontSize={21} variant="h1">
                Pick Your Plan
              </Text>
            )}

            {shop.has_trial && (
              <Text
                className="text--bold text--nuntio text__try-free"
                fontSize={14}
                color="blue "
              >
                Try a 14-day trial for free now!
              </Text>
            )}

            <div className={styles.plans}>
              <PlanTable
                onPaidPlanSelect={handleChangePlan}
                onFreePlanSelect={handleChangePlan}
                loading={loading}
                constant={TRY_FOR_FREE}
              />
            </div>
          </>
        )}

        {showPaymentForm && !isResponse && (
          <>
            <Text
              className="text--bold text__header"
              fontSize={21}
              color="medium-gray"
            >
              You&apos;re one step away from starting your brand.
            </Text>

            {shop.has_trial && (
              <Text
                className="text--bold text--nuntio text__try-free"
                fontSize={14}
              >
                Start your 14-day free trial of the{" "}
                {PLAN_INFO[pendingPlanId].name} now!
              </Text>
            )}

            {!shop.has_trial && (
              <p className="text--bold text--nuntio text__try-free">
                Your subscription to the {PLAN_INFO[pendingPlanId].name} will
                start immediately!
              </p>
            )}
          </>
        )}

        {stripeLoading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}

        {showPaymentForm && (
          <div className={styles.stripeContainer}>
            {clientSecret && !stripeLoading && (
              <Elements options={options} stripe={stripePromise}>
                <UpdatePaymentMethodModalForm
                  handleAddPaymentMethod={handleAddPaymentMethod}
                  redirectParams={`plan_id=${pendingPlanId}&upgrading=true`}
                  handleSuccess={() => handlePlanAutoUpgrade()}
                  plan={pendingPlanId}
                />
              </Elements>
            )}
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}

ChangePlanModal.propTypes = {
  open: PropTypes.bool,
};

ChangePlanModal.defaultProps = {
  open: false,
};
