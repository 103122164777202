import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Text from "components/Text";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import queryClient from "api/queryClient";

import { QUERY, updateShopPayment, updatePlan } from "api";

import Button from "components/Button";

import styles from "./UpdatePaymentMethodModalForm.module.scss";

export default function UpdatePaymentMethodModalForm({
  handleAddPaymentMethod,
  submitButtonText,
  handleSuccess,
  redirectParams,
  plan,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const resetUrlParams = () => {
      // remove the params or the user will get in a loop on confirming
      window.history.pushState(
        {},
        undefined,
        `${`${window.location.protocol}//${window.location.host}${window.location.pathname}`}`
      );
    };

    const setData = async (setupIntent) => {
      await handleAddPaymentMethod(setupIntent.payment_method);
      handleSuccess();
      resetUrlParams();
      setPlanLoading(true);

      queryClient.refetchQueries([QUERY.getUser]);
    };

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );
    const isUpgrading = new URLSearchParams(window.location.search).get(
      "upgrading"
    );

    if (clientSecret) {
      // Retrieve the SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

        switch (setupIntent.status) {
          case "succeeded":
            setPlanLoading(true);
            setData(setupIntent);

            if (isUpgrading) {
              setMessage(
                "Success! Your payment info has been saved. Please wait while we upgrade your plan"
              );
            } else {
              setMessage("Success! Your payment info has been saved.");
            }

            break;

          case "processing":
            setMessage(
              "Processing payment details. We'll update you when processing is complete."
            );
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setErrorMessage(
              "Failed to process payment details. Please try another payment method."
            );
            break;
          default:
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    setLoading(true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const url = new URL(`${window.location}`);
    const params = new URLSearchParams(url.search);
    params.append("plan_id", plan);

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${url.href.split("?")[0]}?${params}`, // in a modal go back to whatever page the user was already on
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form className={styles.form}>
      {!message && (
        <div>
          <PaymentElement
            id="payment-element"
            onChange={(event) => setFormComplete(event.complete)}
          />

          <div className={styles.buttonContainer}>
            <Button
              loading={loading}
              disabled={!stripe || !formComplete}
              onClick={(e) => handleSubmit(e)}
              className={styles.submitButton}
              color="secondary"
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      )}

      {message && (
        <Text
          fontSize={22}
          className={styles.container}
          variant="h6"
          color="gray"
        >
          {message}
        </Text>
      )}

      {planLoading && (
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      )}

      {errorMessage && (
        <Text className={styles.container} variant="error">
          {errorMessage}
        </Text>
      )}
    </form>
  );
}
