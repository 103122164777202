import React from "react";
import PropTypes from "prop-types";

import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import styles from "./ModalWrapper.module.scss";

export default function ModalWrapper(props) {
  const { handleClose, children, isOpen, className } = props;

  const onClose = () => {
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
        },
      }}
      aria-labelledby="Modal Wrapper"
      aria-describedby="Modal Wrapper"
      data-testid="ModalWrapper"
      onBackdropClick={handleClose}
    >
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
        <div
          className={`modal-wrapper-content ${styles.modalWrapper} ${className}`}
        >
          {className !== "product-mobile" ? (
            <Typography
              onClick={(e) => handleClose()}
              className={`${styles.modalCloseButton} close__button`}
            >
              [x] close
            </Typography>
          ) : (
            <Typography
              className={`${styles.modalCloseButton} ${styles.productDetailHeader} ${styles.filterMenuBoxShadow} close__button`}
            >
              <KeyboardBackspaceIcon
                onClick={(e) => handleClose()}
                className={styles.placeIcon}
              />
              <span>Product Detail</span>
            </Typography>
          )}
          {children}
        </div>
      </Slide>
    </Modal>
  );
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

ModalWrapper.defaultProps = {
  isOpen: false,
  className: "",
};
