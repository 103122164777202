import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Button from "components/Button";
import { useMutation } from "react-query";
import Text from "components/Text";
import TotalsWrapper from "views/CheckoutView/components/TotalsWrapper";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import PageHeader from "components/PageHeader";
import { CART_TYPE_INVENTORY, PLAN_FREE_ID } from "constants/constants";

import ChangePlanModal from "components/Modals/ChangePlanModal";

import { completeInventoryOrder } from "api";

import styles from "./CheckoutCompleteView.module.scss";

export default function CheckoutCompleteView() {
  const [isLoading, setLoading] = useState(false);
  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const items = useSelector((state) => state.cart.items);
  const cart = useSelector((state) => state.cart);
  const shipping = useSelector((state) => state.cart.shipping);
  const shop = useSelector((state) => state.profile.shop);
  const completeInventoryOrderMutation = useMutation(completeInventoryOrder);

  return (
    <div className="">
      <PageHeader title="Checkout - Thank You" />
      <Card className={styles.card} variant="outlined">
        <Text color="default" variant="h3" fontSize={24}>
          Thank You
        </Text>
        <Text
          color="default"
          className="text--upper mt-25"
          variant="body1"
          fontSize={12}
        >
          <span className="text--bold">PLEASE Check your email</span> for your
          order confirmation.
        </Text>
        <Text
          color="default"
          className="text--upper mt-10"
          variant="body1"
          fontSize={12}
        />

        {shop.plan === PLAN_FREE_ID && (
          <div className={styles.upsellContainer}>
            <Text variant="h3" fontSize={34}>
              Ready for the next level
            </Text>

            <ul>
              <li>Sell branded products</li>
              <li>Unlock special features</li>
              <li>&amp; More!</li>
            </ul>

            <Button
              onClick={(e) => setPlansModalOpen(e)}
              variant="contained"
              color="secondary"
            >
              Browse Our plans
            </Button>
          </div>
        )}
      </Card>
      <ChangePlanModal
        open={plansModalOpen}
        handleClose={(e) => setPlansModalOpen(false)}
      />
    </div>
  );
}
