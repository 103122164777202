import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./sessionSlice";
import profileReducer from "./profileSlice";
import cartReducer from "./cartSlice";
import categorySlice from "./categorySlice";
import myProductSlice from "./myProductSlice";

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    profile: profileReducer,
    cart: cartReducer,
    category: categorySlice,
    myProduct: myProductSlice,
  },
});
