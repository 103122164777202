import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import PlanTableCardMobile from "components/PlanTableCardMobile";
import { useSelector } from "react-redux";
import { useWindowDimensions } from "hooks/useWindowDimensions";

import PlanTableCard from "../PlanTableCard";
import styles from "./PlanTable.module.scss";

export default function PlanTable({
  loading,
  constant,
  onPaidPlanSelect,
  onFreePlanSelect,
}) {
  const shop = useSelector((state) => state.profile.shop);
  const { width } = useWindowDimensions();
  if (width < 600) {
    return (
      <section className={styles.priceTableMobile}>
        <PlanTableCardMobile
          planId={2}
          hasTrial={shop.has_trial}
          planStatus={constant}
          handleClick={onPaidPlanSelect}
          loading={loading}
          activePlan={shop.plan}
        />
        <PlanTableCardMobile
          planId={3}
          hasTrial={shop.has_trial}
          planStatus={constant}
          handleClick={onPaidPlanSelect}
          loading={loading}
          activePlan={shop.plan}
        />
        <PlanTableCardMobile
          planId={4}
          hasTrial={shop.has_trial}
          planStatus={constant}
          handleClick={onPaidPlanSelect}
          loading={loading}
          activePlan={shop.plan}
        />
        <PlanTableCardMobile
          planId={1}
          planStatus={constant}
          handleClick={onFreePlanSelect}
          loading={loading}
          activePlan={shop.plan}
        />
      </section>
    );
  }

  return (
    <section className={`${styles.priceTable}`}>
      <PlanTableCard planId={0} />
      <Divider
        orientation="vertical"
        flexItem
        className={styles.tableDivider}
      />
      <PlanTableCard
        planId={1}
        planStatus={constant}
        handleClick={onFreePlanSelect}
        loading={loading}
        activePlan={shop.plan}
      />
      <PlanTableCard
        planId={2}
        hasTrial={shop.has_trial}
        planStatus={constant}
        handleClick={onPaidPlanSelect}
        loading={loading}
        activePlan={shop.plan}
      />
      <PlanTableCard
        planId={3}
        hasTrial={shop.has_trial}
        planStatus={constant}
        handleClick={onPaidPlanSelect}
        loading={loading}
        activePlan={shop.plan}
      />
      <PlanTableCard
        planId={4}
        hasTrial={shop.has_trial}
        planStatus={constant}
        handleClick={onPaidPlanSelect}
        loading={loading}
        activePlan={shop.plan}
      />
    </section>
  );
}

PlanTable.propTypes = {
  constant: PropTypes.string.isRequired,
  onPaidPlanSelect: PropTypes.func.isRequired,
  onFreePlanSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

PlanTable.defaultProps = {};
