import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";

export default function CloseButton({ closeToast }) {
  return (
    <i
      className="text--nunito notifications__close-button"
      onClick={(e) => closeToast}
    >
      [x] close
    </i>
  );
}
