import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { useCalcs } from "hooks";
import Button from "components/Button";
import { useMutation } from "react-query";
import Text from "components/Text";
import TotalsWrapper from "views/CheckoutView/components/TotalsWrapper";
import CheckoutFooter from "views/CheckoutView/components/CheckoutFooter";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import PageHeader from "components/PageHeader";
import { CART_TYPE_INVENTORY } from "constants/constants";
import {
  GenericSuccessNotification,
  GenericErrorNotification,
} from "components/Notifications";
import { toast } from "react-toastify";
import { clearCart } from "state/cartSlice";
import {
  completeInventoryOrder,
  completeSamplesOrder,
  createPaymentIntent,
} from "api";
import CheckoutAddPayment from "./components/CheckoutAddPayment";
import CheckoutConfirmPayment from "./components/CheckoutConfirmPayment";

import styles from "./CheckoutPaymentView.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

export default function CheckoutPaymentView() {
  const [clientSecret, setClientSecret] = useState(null);
  const [nextActionType, setNextActionType] = useState(null);
  const [processing, setProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector((state) => state.profile);
  const shop = useSelector((state) => state.profile.shop);
  const [isLoading, setLoading] = useState(false);
  const items = useSelector((state) => state.cart.items);
  const cart = useSelector((state) => state.cart);
  const shipping = useSelector((state) => state.cart.shipping);
  const completeInventoryOrderMutation = useMutation(completeInventoryOrder);
  const completeSamplesOrderMutation = useMutation(completeSamplesOrder);

  /*
  useEffect(() => {
    // Call your backend API to create a PaymentIntent and retrieve its client secret
    createPaymentIntent(20)
      .then((data) => {
        console.log(data);
        setClientSecret(data.clientSecret);
        setNextActionType(data.nextAction?.type || null);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);


*/
  const handlePaymentSuccess = () => {
    setLoading(false);
    dispatch(clearCart());
    navigate("/checkout/thank-you");
  };

  const handleSubmit = () => {
    setLoading(true);
    if (cart.type === CART_TYPE_INVENTORY) {
      completeInventoryOrderMutation.mutate(
        {
          shipping,
          items,
        },
        {
          onSuccess: (response) => {
            if (response.data.requires_action) {
              setClientSecret(response.data.client_secret);
              setNextActionType(response.data.next_action?.type || null);
              setLoading(true);
              return;
            }

            setLoading(false);
            dispatch(clearCart());
            navigate("/checkout/thank-you");
          },
          onError: (err) => {
            setLoading(false);
          },
        }
      );
    } else {
      // is sample order
      completeSamplesOrderMutation.mutate(
        {
          shipping,
          items,
          discount_id: cart.discount?.code || null,
        },
        {
          onSuccess: (response) => {
            if (response.data.requires_action) {
              setClientSecret(response.data.client_secret);
              setNextActionType(response.data.next_action?.type || null);
              return;
            }

            setLoading(false);
            dispatch(clearCart());
            navigate("/checkout/thank-you");
          },
          onError: (err) => {
            setLoading(false);
          },
        }
      );
    }
  };

  return (
    <div
      className={`samples-checkout template-inventory-checkout__shipping checkout-shipping ${styles.checkoutPayment}`}
    >
      <PageHeader title="Checkout - Payment" />
      <Card className={styles.card} variant="outlined">
        <div className="shipping-card-left">
          <Text
            className="text--pb-10 text--bold"
            fontSize={24}
            variant="h2"
            color="medium-gray"
          >
            Payment Information
          </Text>
          <Text className="" fontSize={10} color="medium-gray">
            All transactions are secure and encrypted
          </Text>

          <Text
            className="text--nunito text--spacing-2 text--upper text--pb-5"
            fontSize={10}
          >
            Credit Card Number
          </Text>

          {shop.has_payment_method ? (
            <>
              <div
                className={`${styles.creditCardDisplay} text--nunito text--mb-5 mb-10 mt-10`}
              >
                <p className={styles.stars}>
                  {" "}
                  <span>
                    **** **** ****{" "}
                    {shop.billing_address
                      ? shop?.billing_address.credit_card_digits
                      : "****"}
                  </span>
                </p>
              </div>

              <Text className="text--upper text--spacing-1">
                To update credit card visit{" "}
                <Link
                  className={` ${styles.TextLink} text--info`}
                  to="/profile/billing/update"
                >
                  My Account
                </Link>
              </Text>
            </>
          ) : (
            <CheckoutAddPayment />
          )}
        </div>
        <div className="shipping-card-right">
          <TotalsWrapper
            shipping={shipping}
            shippingCost={shipping.shipping_cost}
            items={items}
          />
        </div>
      </Card>

      <div className="checkout__actions">
        <div className="checkout__breadcrumbs">
          <CheckoutFooter backLink="/checkout/shipping" activeStep={2} />
        </div>

        <div className="checkout-button-container">
          <Button
            disabled={!shop.has_payment_method}
            loading={isLoading}
            onClick={handleSubmit}
            variant="outlined"
            color="primary"
            className=" button button--primary"
          >
            Complete Purchase
          </Button>

          <Elements stripe={stripePromise}>
            <CheckoutConfirmPayment
              nextActionType={nextActionType}
              clientSecret={clientSecret}
              handlePaymentSuccess={handlePaymentSuccess}
              handlePaymentError={() => {
                setNextActionType();
                setClientSecret();
                setLoading(false);
              }}
            />
          </Elements>
        </div>
      </div>
    </div>
  );
}
