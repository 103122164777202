import React from "react";
import { Link } from "react-router-dom";
import Text from "components/Text";
import { toast } from "react-toastify";
import Button from "components/Button";

export function GenericSuccessNotification({
  closeToast,
  toastProps,
  text,
  title,
}) {
  return (
    <div>
      <span className="toast-success-text text--bold">
        {title || "Success!"}
      </span>

      <span className="toast-content-text">{text}</span>
    </div>
  );
}

export function GenericErrorNotification({
  closeToast,
  toastProps,
  text,
  title,
}) {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20 "
        variant="body1"
        fontSize={16}
      >
        {title || "OOPS!"}
      </Text>

      <Text variant="body1" fontSize={16} className="toast-content-text">
        {text || "An error occurred. Contact us to resolve this."}
      </Text>
    </div>
  );
}

export function InventoryAddedNotification({ closeToast, toastProps }) {
  return (
    <div>
      <span className="toast-success-text">Success!</span>
      <span className="toast-content-text">
        This inventory has been added to your{" "}
      </span>
      <Link className="toast-link" to="/cart">
        Cart.
      </Link>
    </div>
  );
}

export const addSampleToCartSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text text--bold">Success!</span>

    <span className="toast-content-text">Sample has been added to your </span>

    <Link className="toast-link" to="/cart">
      Cart.
    </Link>
  </div>
);

export const visulizationInProgress = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text text--bold">
      Hang tight! We&apos;re creating your custom product images.
    </span>

    <span className="toast-content-text">
      You&apos;ll receive an email when they are complete.
    </span>
  </div>
);

export const brandPlateSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">
      Your Brand Plate has been ordered.
    </span>
  </div>
);

export function AddToStoreSuccess({ closeToast, toastProps, product }) {
  return (
    <div>
      <span className="text--bold toast-success-text">Success!</span>

      <span className="toast-content-text">
        {product} has been added to MY PRODUCTS.
      </span>
    </div>
  );
}

export const publishToStoreSuccess = ({ closeToast, toastProps }) => (
  <div>
    <span className="toast-success-text">Success!</span>

    <span className="toast-content-text">Product Published.</span>
  </div>
);

export function ServerError({ closeToast, toastProps, message }) {
  return (
    <div>
      <span className="toast-error -text">Error</span>

      <span className="toast-content-text">{message}</span>
    </div>
  );
}

export function AuthenticationError({ closeToast, toastProps, message }) {
  return (
    <div>
      <h2 className="toast-error-text">Authentication Error!</h2>

      <p className="toast-content-text">Please Login Again.</p>
    </div>
  );
}

export function NetworkError({ closeToast, toastProps, message }) {
  return (
    <div>
      <h2 className="toast-error-text">Error!</h2>

      <p className="toast-content-text">Could not connect to server.</p>
    </div>
  );
}

export function InventoryWithSampleError({ closeToast, toastProps, message }) {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper mb-25"
      >
        Oops!
      </Text>

      <p className="toast-content-text">
        <span className="text--bold">INVENTORY ORDERS</span> and{" "}
        <span className="text--bold">SAMPLE ORDERS</span> can&apos;t be ordered
        at the same time. Please clear your cart to continue.
      </p>
    </div>
  );
}

export function NoLogoError({ closeToast, toastProps, message }) {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper mb-25"
      >
        Oops!
      </Text>

      <Text
        fontSize={16}
        variant="body1"
        color="white"
        className="toast-content-text mb-25"
      >
        You need to upload a logo first!
      </Text>

      <Link className="toast-link" to="/profile?action=upload-logo">
        Click here to upload one.
      </Link>
    </div>
  );
}

export function NoStoreConnectionError({ closeToast, toastProps, message }) {
  return (
    <div>
      <Text
        variant="body1"
        fontSize={20}
        color="white"
        className="toast-error-text text--upper text--bold mb-25"
      >
        Oops!
      </Text>

      <p className="toast-error-content">
        You need to{" "}
        <span className="text--upper text--bold">connect your store</span>{" "}
        before publishing products
      </p>
      <Link className="toast-link text--no-underline" to="/profile/connect">
        <Button className="mt-20" color="secondary">
          Connect your Store
        </Button>
      </Link>
    </div>
  );
}

export function NoBillingMethodError({ closeToast, toastProps, message }) {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20"
        variant="body1"
        fontSize={16}
      >
        OOPS!
      </Text>

      <Text className="toast-error-content mb-20" variant="body1" fontSize={18}>
        You need to upload billing details first! Click here to add your
        details.
      </Text>
      <Link
        className="toast-link text--no-underline"
        to="/profile/billing/update"
      >
        <Button color="secondary" variant="contained" className="button__link">
          Update Payment Info
        </Button>
      </Link>
    </div>
  );
}

export function SampleWithInventoryError({ closeToast, toastProps, message }) {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20 "
        variant="body1"
        fontSize={16}
      >
        OOPS!
      </Text>

      <p className="toast-content-text">
        You can&apos;t order samples and inventory at the same time. Please
        clear your shopping bag to continue.
      </p>
    </div>
  );
}

export function SampleKitError({ closeToast, toastProps, message }) {
  return (
    <div>
      <Text
        className="toast-error-text text--bold mb-20 "
        variant="body1"
        fontSize={16}
      >
        OOPS!
      </Text>

      <p className="toast-content-text">
        sample kits are only available for
        <span className="text--bold">SAMPLE ORDERS.</span>
      </p>
    </div>
  );
}

export const notifications = {
  noStoreConnectedError() {
    toast.error(NoStoreConnectionError);
  },
  noBillingMethodError() {
    toast.error(NoBillingMethodError);
  },
  showErrorMessage(text) {
    toast.error(<GenericErrorNotification text={text} />, {
      toastId: "error-generic",
    });
  },
};
