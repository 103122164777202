import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken, logout } from "state/sessionSlice";
import { clearProfile } from "state/profileSlice";
import { clearCart } from "state/cartSlice";
import queryClient from "api/queryClient";

import { QUERY } from "api";

// logout view, can be used to rest someones front end
export default function LogoutView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.profile);

  // save shop url for when they Logout
  useEffect(() => {
    const persistLogin = new URLSearchParams(window.location.search).get(
      "no_persist"
    );

    // clear profile
    dispatch(logout(persistLogin ? null : profile.username));

    // remove cart items and reset
    dispatch(clearCart());
    queryClient.clear();
    navigate("/login");

    // clear session variables for connect store
    sessionStorage.removeItem("woocommerce_store");
    sessionStorage.removeItem("shopify_store");

    dispatch(clearProfile());
  }, []);

  return <div />;
}
