import React, { useState } from "react";
import Text from "components/Text";
import { useSelector } from "react-redux";
import Button from "components/Button";
import PropTypes from "prop-types";
import shopifyLogo from "assets/images/shopify-logo.png";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import wixLogo from "assets/images/wix-logo.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  SHOP_INTEGRATION_TYPE_SHOPIFY,
  SHOP_INTEGRATION_TYPE_WOOCOMMERCE,
  SHOP_INTEGRATION_TYPE_WIX,
} from "constants/constants";
import { updateShop, getUser } from "api";
import OnboardingWrapper from "../OnboardingWrapper";
import styles from "./OnboardingStepFive.module.scss";

export default function OnboardingStepFive() {
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(false);

  const handleNextStep = async () => {
    setLoading(true);
    await updateShop(shop.id, { onboarding_step: 5 });

    // do a reload to show loading icon
    window.location.href = "/?onboarding_complete=true";
  };

  const getLogo = () => {
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_SHOPIFY) {
      return (
        <img alt="Shopify Logo" className="shopify-icon" src={shopifyLogo} />
      );
    }
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WOOCOMMERCE) {
      return (
        <img
          alt="Shopify Logo"
          className="shopify-icon"
          src={wooCommerceLogo}
        />
      );
    }
    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WOOCOMMERCE) {
      return (
        <img
          alt="Woocommerce Logo"
          className="shopify-icon"
          src={wooCommerceLogo}
        />
      );
    }

    if (shop.integration_type === SHOP_INTEGRATION_TYPE_WIX) {
      return <img alt="Wix Logo" className="shopify-icon" src={wixLogo} />;
    }

    return <p />;
  };

  return (
    <OnboardingWrapper>
      <div className={styles.onboardingStepFive}>
        <div className={styles.connectedMessageContainer}>
          <Text fontSize={24} className="text--bold text--blue">
            Congrats, your <span>{shop.integration_type}</span> store is now
            connected!
          </Text>
        </div>

        <div className={styles.storeNameContainer}>
          {getLogo()}

          <Text fontSize={16} className={`text--nunito ${styles.textDomain}`}>
            {shop.url_display_name}
          </Text>
          <CheckCircleIcon style={{ color: "#00AA51" }} />
        </div>

        <div className={styles.actionContainer}>
          <Button
            loading={loading}
            size="large"
            color="secondary"
            onClick={handleNextStep}
          >
            Launch the app
          </Button>
        </div>
      </div>
    </OnboardingWrapper>
  );
}
