import React, { useEffect, useState } from "react";
import Text from "components/Text";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { useCalcs } from "hooks";

import Button from "components/Button";
import { setCartDiscount, clearCartDiscount } from "state/cartSlice";
import { checkCoupon } from "api/endpoints/cartApi";

import styles from "./CheckoutDiscountForm.module.scss";

export default function CheckoutDiscountForm({ disabled }) {
  const calcs = useCalcs();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [couponMessage, setCouponMessage] = useState(false);
  const [code, setCode] = useState();
  const cartDiscount = useSelector((state) => state.cart.discount);

  useEffect(() => {
    if (cartDiscount) {
      setCode(cartDiscount.code);
    }
  }, [cartDiscount]);

  const handleCheckCoupon = async () => {
    if (cartDiscount.code) {
      dispatch(clearCartDiscount());
      setCode("");
      return;
    }

    try {
      setLoading(true);
      const response = await checkCoupon(code);

      if (!response.data.valid) {
        setCouponMessage("Invalid code entered.");
        setLoading(false);
        setCode("");
        return;
      }

      dispatch(
        setCartDiscount({
          code: response.data.code,
          value: response.data.value,
          type: response.data.discount_type,
        })
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="checkoutDiscountForm">
      <TextField
        id="coupon"
        label="Discount Code"
        variant="outlined"
        disabled={disabled}
        value={code}
        InputProps={{ readOnly: !!cartDiscount?.code }}
        InputLabelProps={{
          shrink: true,
        }}
        required
        onChange={(e) => {
          setCode(e.target.value);
          setCouponMessage("");
        }}
      />

      {couponMessage && (
        <span className="text--error mt-10">{couponMessage}</span>
      )}

      <Button
        disabled={!code || disabled}
        onClick={handleCheckCoupon}
        color="secondary"
        variant=""
        loading={loading}
        className={cartDiscount?.code ? "has-code" : ""}
      >
        {cartDiscount?.code ? "Remove" : "Apply"} Discount
      </Button>
    </div>
  );
}
