import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Text from "components/Text";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";

import { updateShopPayment, updatePlan } from "api";

import Button from "components/Button";

import styles from "./UpdatePaymentMethodForme.module.scss";

const DOMAIN = String(process.env.REACT_APP_DOMAIN || "http://localhost:3000");

const RETURN_URL = `${DOMAIN}/profile/billing/update`;

export default function UpdatePaymentMethodForm({
  handleNextStep,
  handleAddPaymentMethod,
  submitButtonText,
  onSuccess,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const shop = useSelector((state) => state.profile.shop);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const setData = async (setupIntent) => {
      await handleAddPaymentMethod(setupIntent.payment_method);
      onSuccess();
    };

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );

    if (clientSecret) {
      // Retrieve the SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

        switch (setupIntent.status) {
          case "succeeded":
            setData(setupIntent);

            // setMessage("Success! Your payment method has been saved");

            break;

          case "processing":
            setMessage(
              "Processing payment details. We'll update you when processing is complete."
            );
            break;

          case "requires_payment_method":
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setErrorMessage(
              "Failed to process payment details. Please try another payment method."
            );
            break;
          default:
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (event) => {
    setLoading(true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: RETURN_URL,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form className="payment-container">
      {!message && (
        <div>
          <PaymentElement id="payment-element" />
          <div className={styles.buttonContainer}>
            <img
              className="poweredByStripe"
              src={poweredByStripeImg}
              alt="powered by stripe"
            />
            <Button
              loading={loading}
              disabled={!stripe}
              onClick={(e) => handleSubmit(e)}
              className={styles.submitButton}
              color="primary"
              variant="contained"
            >
              Update Information
            </Button>
          </div>
        </div>
      )}
      {message && (
        <Text fontSize={22} className={styles.container} color="success">
          {message}
        </Text>
      )}
      {errorMessage && (
        <Text className={styles.container} color="error">
          {errorMessage}
        </Text>
      )}
    </form>
  );
}
