import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { connect, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  updatePlan,
  createSetupIntent,
  updateShopPayment,
  updateShop,
  getUser,
} from "api";
import {
  PLAN_INFO,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_VIP_ID,
  SHOP_ONBOARDING_SHOPIFY,
} from "constants/constants";
import queryClient from "api/queryClient";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";
import { STRIPE_THEME } from "theme";
import { GenericSuccessNotification } from "components/Notifications";

import PlanCard from "components/PlanCard";
import Text from "components/Text";
import PaymentUpdateResponseModalForm from "./PaymentUpdateResponseForm";

import styles from "./PaymentUpdateResponseModal.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

function PaymentUpdateResponseModal({ props, open, handleClose }) {
  const changePlanMutation = useMutation(updatePlan);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [clientSecret, setClientSecret] = useState();
  const [responseError, setResponseError] = useState();
  const shop = useSelector((state) => state.profile.shop);

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const getData = async () => {
      try {
        // await getUser();
        const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
        setClientSecret(response.data.client_secret);
        setLoading(false);
      } catch (e) {
        // console.error(e);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleSuccess = () => {
    toast.success(
      <GenericSuccessNotification text="Your payment method has been saved." />
    );
  };

  const handleAddPaymentMethod = async (paymentId) => {
    await updateShopPayment(paymentId);
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  return (
    <ModalWrapper
      handleClose={handleClose}
      isOpen={open}
      data-testid="PaymentMethodModal"
    >
      <div>
        {loading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}

        {message && <p>{message}</p>}

        <div className={styles.stripeContainer}>
          {clientSecret && !loading && (
            <Elements options={options} stripe={stripePromise}>
              <PaymentUpdateResponseModalForm
                handleSuccess={handleSuccess}
                handleAddPaymentMethod={handleAddPaymentMethod}
              />
            </Elements>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}

PaymentUpdateResponseModal.propTypes = {
  open: PropTypes.bool,
};

PaymentUpdateResponseModal.defaultProps = {
  open: false,
};

export default PaymentUpdateResponseModal;
