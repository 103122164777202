import PropTypes from "prop-types";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import styles from "./OnboardingProgressStepper.module.scss";

const steps = [
  { name: "Pick Your Plan", step: 1 },
  { name: "Payment Info", step: 2 },
];

function OnboardingProgressStepper(props) {
  const { activeStep, handleBack } = props;
  const onboardingStep = useSelector(
    (state) => state.profile.shop.onboarding_step
  );

  const isActive = (step) => {
    if (step === activeStep) {
      return true;
    }
    return false;
  };

  const handleClick = (index) => {
    if (index < activeStep) {
      handleBack();
    }
  };

  return (
    <div className={`breadcrumbs ${styles.breadcrumbs}`}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step
              key={label.name}
              disabled={activeStep !== label.step}
              {...stepProps}
              active={isActive(index)}
              className={index === activeStep ? "text--bold" : ""}
            >
              <StepLabel
                className={!isActive(index) ? "pointer" : ""}
                onClick={(e) => handleClick(index)}
                {...labelProps}
              >
                {label.name}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default OnboardingProgressStepper;
