import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { Elements } from "@stripe/react-stripe-js";
import AddPaymentMethodForm from "components/AddPaymentMethodForm";
import {
  PLAN_INFO,
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_VIP_ID,
} from "constants/constants";
import Button from "components/Button";
import Text from "components/Text";
import { createSetupIntent, updateShopPayment, updateShop, getUser } from "api";
import { setShop } from "state/profileSlice";

import PaymentMethodStatus from "components/PaymentMethodStatus";
import poweredByStripeImg from "assets/images/powered-by-stripe.png";
import { STRIPE_THEME } from "theme";
import OnboardingWrapper from "../OnboardingWrapper";
import OnboardingProgressStepper from "../OnboardingProgressStepper";
import styles from "./OnboardingStepThree.module.scss";

const stripePromise = loadStripe(
  String(
    process.env.REACT_APP_STRIPE_KEY ||
      "pk_test_51HKzs6EzjuN8pWiu5IlHaEiXjxFhhddYQVNsKlvICXoY04sCK02kZdRlgcBOt3EkErnSO6G1ma2BApBNQvJtW6cr00ivQWxAXA"
  )
);

export default function OnboardingStepThree() {
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();
  const shop = useSelector((state) => state.profile.shop);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const [responseError, setResponseError] = useState();

  useEffect(() => {
    if (!shop.plan) {
      return;
    }

    const getData = async () => {
      try {
        // await getUser();
        const response = await createSetupIntent(PLAN_INFO[shop.plan].price);
        setClientSecret(response.data.client_secret);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleNextStep = () => {
    updateShop(shop.id, { onboarding_step: 3 }).then((res) => {
      dispatch(setShop(res.data));
    });
  };

  const handleBack = () => {
    setLoading(true);
    updateShop(shop.id, { onboarding_step: 1 }).then((res) => {
      setLoading(false);
      dispatch(setShop(res.data));
    });
  };

  const handleAddPaymentMethod = async (paymentId) => {
    await updateShopPayment(paymentId);
  };

  const options = {
    clientSecret,
    appearance: STRIPE_THEME,
  };

  return (
    <OnboardingWrapper>
      <div className={styles.onboardingStepThree}>
        <div className={styles.upsellContainer}>
          <Text fontSize={24} variant="h2" className="text--spacing-0">
            Try the {shop.plan_name}
          </Text>
          <Text fontSize={24} className="text--nunito text--blue text--bold">
            14 days free!
          </Text>
          <Text fontSize={10} className="text--nunito">
            Then, ${shop.plan === PLAN_GROWTH_ID && "29"}
            {shop.plan === PLAN_SCALE_ID && "59"}
            {shop.plan === PLAN_VIP_ID && "99"} per month
          </Text>
        </div>

        {loading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}

        {message && <p>{message}</p>}

        <div className={styles.stripeContainer}>
          {clientSecret && !loading && (
            <Elements options={options} stripe={stripePromise}>
              <AddPaymentMethodForm
                handleNextStep={handleNextStep}
                handleAddPaymentMethod={handleAddPaymentMethod}
                submitButtonText="Sign me up"
              />
            </Elements>
          )}
        </div>
        <OnboardingProgressStepper handleBack={handleBack} activeStep={1} />

        <div className={styles.poweredByContainer}>
          <a
            target="__blank"
            href="https://blankabrand.com/blanka-terms-and-conditions/"
          >
            Terms and Conditions
          </a>
          <img
            className={styles.poweredBy}
            src={poweredByStripeImg}
            alt="powered by stripe"
          />
        </div>
      </div>
    </OnboardingWrapper>
  );
}
