import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function CrossMarkIcon(props) {
  return (
    <div className="icon-root" style={{ width: "24px", height: "24px" }}>
      <SvgIcon {...props} className="icon" viewBox="0 0 24 24">
        <g id="X error">
          <circle
            id="Ellipse 763"
            cx="10.1102"
            cy="11.3008"
            r="10"
            fill="#246071"
          />
          <path
            id="X"
            d="M5.57412 16.9375L9.36433 11.8792L5.71502 7.00405H7.81443L10.3929 10.5688L12.9714 7.00405H15.0849L11.4356 11.8792L15.2117 16.9375H13.0982L10.3929 13.2177L7.70171 16.9375H5.57412Z"
            fill="white"
          />
        </g>
      </SvgIcon>
    </div>
  );
}

export default CrossMarkIcon;
