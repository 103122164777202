/**
 * keep all the queries in constants
 */

const QUERY = {
  getUser: "get-user",
  getProducts: "get-products",
  getMyProducts: "get-my-products",
  getCustomerOrders: "get-customer-orders",
  getInventoryOrders: "get-inventory-orders",
  getSampleOrders: "get-sample-orders",
};

export default QUERY;
