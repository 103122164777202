import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Button from "components/Button";
import PasswordStrengthBar from "components/PasswordStrengthBar";
import Text from "components/Text";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import {
  createUser,
  googleSSOLogin,
  appleSSOLogin,
  login,
  updateShop,
} from "api";
import { setToken } from "state/sessionSlice";
import Divider from "@mui/material/Divider";
import { useQueryParam } from "hooks";
import AppleLoginButton from "components/AppleLoginButton";
import GoogleLoginButton from "components/GoogleLoginButton";

import styles from "./SignUpForm.module.scss";

export default function SignUpForm() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);
  const queryParams = useQueryParam();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState();

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [loading, setLoading] = useState(false);
  const mutation = useMutation(createUser);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
  } = useForm({ mode: "onChange" });
  const watchPassword = watch("password");
  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate({
        pathname: "/",
        search: window.location.search,
      });
    }
  }, [isLoggedIn]);

  const sendEvent = () => {
    let signupSource = "organic";
    if (sessionStorage.getItem("woocommerce_store")) {
      signupSource = "woocomerce";
    } else if (sessionStorage.getItem("wix_store")) {
      signupSource = "wix";
    } else if (sessionStorage.getItem("shopify_store")) {
      signupSource = "shopify";
    }
    window.gtag("event", "user_registration", {
      event_category: "user",
      value: `${signupSource}`,
    });
  };
  sessionStorage.getItem("woocommerce_store");

  const onSubmit = async (data) => {
    setLoading(true);
    mutation.mutate(data, {
      onSuccess: (response) => {
        login({ username: data.email, password: data.password }).then((res) => {
          dispatch(setToken(res.data));
          setLoading(false);
          sendEvent();
          navigate("/");
        });
      },
      onError: (err) => {
        setLoading(false);
        if (err.response.data?.error_code === "ERROR_PASSWORD_STRENGTH") {
          setMessage(err.response.data.message);
          return;
        }

        setMessage(
          err.response.data.message
            ? err.response.data.message
            : "There was an error creating your account"
        );
      },
    });
  };

  const handleGoogleLogin = async (googleData) => {
    try {
      setLoading(true);
      const response = await googleSSOLogin(googleData.access_token);
      await dispatch(
        setToken({
          access: response.data.access_token,
          refresh: response.data.refresh_token,
        })
      );
      sendEvent();
      setLoading(false);
      navigate("/");
    } catch (err) {
      setLoading(false);

      setMessage(
        `Oops! Could not login with your Google account. ${
          err.response?.data?.non_field_errors[0]
            ? err.response?.data.non_field_errors[0]
            : ""
        }`
      );
    }
  };

  const handleAppleLogin = async (code) => {
    try {
      setLoading(true);
      const response = await appleSSOLogin(code);
      await dispatch(
        setToken({
          access: response.data.access_token,
          refresh: response.data.refresh_token,
        })
      );
      setLoading(false);
      sendEvent();

      navigate("/");
    } catch (err) {
      setLoading(false);
      setMessage(
        `Oops! Could not login with your Apple account. ${
          err.response?.data?.non_field_errors[0]
            ? err.response?.data.non_field_errors[0]
            : ""
        }`
      );
    }
  };

  return (
    <div className={styles.signUpFormContainer}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div />

        <div className={styles.ssoContainer}>
          <GoogleLoginButton handleLogin={handleGoogleLogin} />
          <AppleLoginButton handleLogin={handleAppleLogin} />
        </div>

        <Divider className={styles.divider} variant="middle">
          OR
        </Divider>

        <div className={styles.fullNameContainer}>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name"
                className={styles.marginTen}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            rules={{ required: true }}
          />

          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name"
                className={styles.padding}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            rules={{ required: true }}
          />
        </div>

        <div className={styles.emailPassContainer}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: /^\S+@\S+$/ }}
            render={({ field }) => (
              <TextField
                {...field}
                className={styles.textFieldWidth}
                label="Email"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>

        <div className={styles.emailPassContainer}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                className={styles.textFieldWidth}
                type="password"
              />
            )}
            rules={{ required: true }}
          />
          <PasswordStrengthBar
            password={watchPassword}
            width="300px"
            margin="10px 0 0 0"
            handleStrengthChange={(strength) => setPasswordStrength(strength)}
            className={styles.passwordStrengthBar}
          />

          <div className={styles.termsConditionsContainer}>
            <p className={styles.termsText}>
              By signing up you agree to Blanka&apos;s{" "}
              <a
                target="__blank"
                href="https://blankabrand.com/blanka-terms-and-conditions/"
              >
                Terms and Conditions
              </a>{" "}
              and{" "}
              <a
                target="__blank"
                href="https://faq.blankabrand.com/en/articles/5924132-how-does-blanka-handle-subscription-refunds"
              >
                Refund &amp; Cancellation Policy.
              </a>
            </p>
          </div>
        </div>

        <div className={styles.actionContainer}>
          <Button
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            disabled={
              !isDirty ||
              !isValid ||
              watchPassword.length < 8 ||
              passwordStrength < 3
            }
            color="secondary"
            type="submit"
            variant="contained"
            size="large"
          >
            SIGN ME UP
          </Button>

          <Text
            fontSize={10}
            className={`text--nunito text--error ${styles.textError}`}
          >
            {" "}
            {message}
          </Text>
        </div>
      </form>
    </div>
  );
}
